<template>
  <b-card
    v-if="automation"
    no-body
  >
    <template #header>
      <div
        class="d-flex flex-column flex-wrap flex-md-row justify-content-between align-items-md-center font-weight-bold w-100"
        style="row-gap: .5rem"
      >
        <!-- CARD HEADER LEFT -->
        <div class="font-medium-4 d-flex align-items-center text-primary">
          <feather-icon
            class="mr-75"
            icon="CpuIcon"
            size="30"
          />

          <div>
            <div>
              <template v-if="automation.name">
                {{ automation.name }}
              </template>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </div>
            <div
              class="d-flex flex-column flex-wrap flex-md-row align-items-start font-small-3"
              style="row-gap: .34rem"
            >
              <b-badge
                :variant="automation.active ? 'light-success' : 'light-danger'"
                class="mr-md-25"
              >
                <span>
                  {{ $t('Automation') }}

                  {{ $t(automation.active ? 'SessionActive' : 'SessionInactive').toLowerCase() }}
                </span>
              </b-badge>

              <b-badge
                variant="light-warning"
                class="mr-md-25"
              >
                {{ $t(automationTriggerTypeByValue(automation.automationProcedureTrigger.type)) }}
              </b-badge>

              <span v-if="getTypeAdditionalFields.includes(CONTACT_STATUSES)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactStatusFrom"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeFrom')"
                  style="white-space: pre-wrap"
                >
                  {{ automation.automationProcedureTrigger.contactStatusFrom.name }}
                </b-badge>

                <feather-icon
                  v-if="automation.automationProcedureTrigger.contactStatusFrom && automation.automationProcedureTrigger.contactStatusTo"
                  icon="ArrowRightIcon"
                  class="mx-25"
                />

                <b-badge
                  v-if="automation.automationProcedureTrigger.contactStatusTo"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeTo')"
                >
                  {{ automation.automationProcedureTrigger.contactStatusTo.name }}
                </b-badge>
              </span>

              <!-- Additional Fields -->
              <span v-if="getTypeAdditionalFields.includes(THREAD_STATUSES)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeFrom')"
                  style="white-space: pre-wrap"
                >
                  {{ automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom.name }}
                </b-badge>

                <feather-icon
                  v-if="automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom && automation.automationProcedureTrigger.contactThreadFunnelTypePointTo"
                  icon="ArrowRightIcon"
                  class="mx-25"
                />

                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadFunnelTypePointTo"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeTo')"
                >
                  {{ automation.automationProcedureTrigger.contactThreadFunnelTypePointTo.name }}
                </b-badge>
              </span>

              <!-- Webhook Fields -->
              <span v-if="getTypeAdditionalFields.includes(CONTACT_APPLICATION_INTEGRATION_TYPE)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactApplicationIntegration && integrationTypes.find(t => t.value === automation.automationProcedureTrigger.contactApplicationIntegration.type)"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('Webhook')"
                  style="white-space: pre-wrap"
                >
                  {{ integrationTypes.find(t => t.value === automation.automationProcedureTrigger.contactApplicationIntegration.type).label }} - {{ automation.automationProcedureTrigger.contactApplicationIntegration.name }}
                </b-badge>

                <b-badge
                  v-else-if="automation.automationProcedureTrigger.integrationFacebookWebhook"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('Webhook')"
                >
                  Facebook - {{ automation.automationProcedureTrigger.integrationFacebookWebhook.name }}
                </b-badge>
              </span>

              <!-- Task Types -->
              <span v-if="getTypeAdditionalFields.includes(TASK_TYPES)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadTaskStatusTo"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeTo')"
                  class="mr-25 mb-25"
                >
                  {{ $t(`task.status.${automation.automationProcedureTrigger.contactThreadTaskStatusTo}`) }}
                </b-badge>
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadTaskTypes"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('task.Type')"
                  style="white-space: pre-wrap"
                >
                  {{ automation.automationProcedureTrigger.contactThreadTaskTypes.map(e => e.name).join(', ') }}
                </b-badge>
              </span>
              <!-- Additional Fields -->
              <span v-if="getTypeAdditionalFields.includes(ORDER_STATUSES)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadOrderStatusFrom"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeFrom')"
                  style="white-space: pre-wrap"
                >
                  {{ $tc(`offer.status.${automation.automationProcedureTrigger.contactThreadOrderStatusFrom}`, 2) }}
                </b-badge>

                <feather-icon
                  v-if="automation.automationProcedureTrigger.contactThreadOrderStatusFrom && automation.automationProcedureTrigger.contactThreadOrderStatusTo"
                  icon="ArrowRightIcon"
                  class="mx-25"
                />
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadOrderStatusTo"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeTo')"
                >
                  {{ $tc(`offer.status.${automation.automationProcedureTrigger.contactThreadOrderStatusTo}`, 2) }}
                </b-badge>
              </span>

              <!-- Additional Fields -->
              <span v-if="getTypeAdditionalFields.includes(OFFER_STATUSES)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadOfferStatusFrom"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeFrom')"
                  style="white-space: pre-wrap"
                >
                  {{ $t(`AgreementStatuses.${automation.automationProcedureTrigger.contactThreadOfferStatusFrom}`) }}
                </b-badge>
                <feather-icon
                  v-if="automation.automationProcedureTrigger.contactThreadOfferStatusFrom && automation.automationProcedureTrigger.contactThreadOfferStatusTo"
                  icon="ArrowRightIcon"
                  class="mx-25"
                />
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadOfferStatusTo"
                  v-b-tooltip
                  variant="light-primary"
                  :title="$t('ChangeTo')"
                >
                  {{ $t(`AgreementStatuses.${automation.automationProcedureTrigger.contactThreadOfferStatusTo}`) }}
                </b-badge>
              </span>

              <!-- Additional Fields -->
              <span v-if="getTypeAdditionalFields.includes(CHECKLIST_POINT)">
                <b-badge
                  v-if="automation.automationProcedureTrigger.contactThreadChecklistTypePoint && automation.automationProcedureTrigger.contactThreadChecklistTypePoint.name"
                  v-b-tooltip
                  :title="$t('ChecklistPoints')"
                  variant="light-primary"
                  style="white-space: pre-wrap"
                >
                  {{ automation.automationProcedureTrigger.contactThreadChecklistTypePoint.name }} - {{ $t(automationCheckboxActionsByValue(automation.automationProcedureTrigger.contactThreadChecklistPointAction)) }}
                </b-badge>
              </span>
            </div>
          </div>
        </div>
        <!-- CARD HEADER LEFT::END -->

        <!-- CARD HEADER RIGHT -->
        <div
          v-if="checkRequiredPermissions([$roles.AUTOMATION_MODIFY])"
          class="d-flex"
        >
          <div>
            <feather-icon
              v-b-tooltip.hover
              :title="$t('Delete')"
              size="17"
              class="ml-md-75 cursor-pointer"
              icon="TrashIcon"
              @click="$emit('delete')"
            />

            <feather-icon
              v-b-tooltip.hover
              :title="$t(automation.active ? 'Deactivate' : 'Activate')"
              size="17"
              class="ml-75 cursor-pointer"
              :icon="automation.active ? 'XIcon' : 'CheckIcon'"
              @click="$emit('change-active')"
            />

            <router-link v-b-tooltip :title="$t('Edit')" :to="{ name: 'automations', params: { automationId: automation.id } }">
              <feather-icon
                size="17"
                class="ml-75 cursor-pointer"
                icon="EditIcon"
              />
            </router-link>

            <router-link v-b-tooltip :title="$t('Copy')" :to="{ name: 'automations', params: { automationId: 'new', automationCopyId: automation.id }, props: { automationCopyId: 1 } }">
              <feather-icon
                size="17"
                class="ml-75 cursor-pointer"
                icon="CopyIcon"
              />
            </router-link>
          </div>
        </div>
        <!-- CARD HEADER RIGHT::END -->
      </div>
    </template>

    <b-card-body
      class="d-flex flex-column flex-wrap flex-md-row"
      style="row-gap: 1.5rem"
    >
      <!-- CARD HEADER::END -->
      <!-- CARD BODY -->
      <!-- CARD BODY::END -->
      <div
        class="w-100"
        :class="{ 'hidden-content': !showAllItems && (automation.automationProcedureActions.length > 3 || automation.automationProcedureConditions > 3)}"
      >
        <b-row style="row-gap: .5rem">
          <b-col
            lg="6"
            class="d-flex flex-column align-items-start justify-content-between"
          >
            <h6
              class="text-center d-flex justify-content-between w-100"
              style="column-gap: .5rem"
            >
              <span
                class="d-flex align-items-center"
                style="column-gap: .75rem"
              >
                <feather-icon
                  icon="ShareIcon"
                  size="20"
                />

                {{ $t('AdditionalConditions') }}
              </span>

              <b-badge variant="light-primary">
                {{ automation.automationProcedureConditions.length || 0 }}
              </b-badge>
            </h6>

            <app-timeline v-if="automation.automationProcedureConditions.length">
              <app-timeline-item
                v-for="(condition, conditionIndex) in visibleConditions"
                :key="`action_${automation.id}_${conditionIndex}`"
                variant="light-primary"
              >
                <automation-details-condition
                  :index="conditionIndex"
                  :item="condition"
                  :show-actions="false"
                />
              </app-timeline-item>
            </app-timeline>
          </b-col>

          <b-col
            lg="6"
            class="d-flex flex-column align-items-start justify-content-between"
          >
            <h6
              class="text-center d-flex justify-content-between w-100"
              style="column-gap: .5rem"
            >
              <span
                class="d-flex align-items-center"
                style="column-gap: .75rem"
              >
                <feather-icon
                  icon="StarIcon"
                  size="20"
                />

                {{ $t('Actions') }}
              </span>

              <b-badge variant="light-primary">
                {{ automation.automationProcedureActions.length || 0 }}
              </b-badge>
            </h6>

            <app-timeline v-if="automation.automationProcedureActions.length">
              <app-timeline-item
                v-for="(action, actionIndex) in visibleActions"
                :key="`action_${automation.id}_${actionIndex}`"
                variant="light-primary"
              >
                <automation-details-action
                  is-nested
                  :index="actionIndex"
                  :item="action"
                  :show-actions="false"
                />
              </app-timeline-item>
            </app-timeline>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="(automation.automationProcedureActions.length > 3 || automation.automationProcedureConditions > 3)"
        class="text-center text-primary cursor-pointer"
        :class="{ 'mt-2': showAllItems }"
        @click="showAllItems = !showAllItems"
      >
        {{ showAllItems ? $t('ShowLess') : $t('ShowMore') }}
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AutomationDetailsCondition from '@/views/settings/automations/components/AutomationDetailsCondition.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AutomationDetailsAction from '@/views/settings/automations/components/AutomationDetailsAction.vue'
// eslint-disable-next-line import/extensions
import {
  automationCheckboxActionsByValue,
  automationsTriggerTypes,
  automationTriggerTypeByValue,
  CHECKLIST_POINT,
  CONTACT_APPLICATION_INTEGRATION_TYPE,
  CONTACT_STATUSES,
  OFFER_STATUSES,
  TASK_TYPES,
  ORDER_STATUSES,
  THREAD_STATUSES,
} from '@/@types/automationsTypes'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'AutomationListCard',
  components: {
    AutomationDetailsAction,
    AppTimelineItem,
    AutomationDetailsCondition,
    AppTimeline,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    automation: {
      default: null,
      required: true,
    },
  },
  data: () => ({
    integrationTypes: [
      { label: 'Google', value: 'GOOGLE_ADS' },
      { label: 'HTML', value: 'HTML' },
      { label: 'Wordpress', value: 'WORDPRESS' },
      { label: 'Facebook', value: 'FACEBOOK' },
    ],
    automationsTriggerTypes,
    THREAD_STATUSES,
    CONTACT_STATUSES,
    CHECKLIST_POINT,
    ORDER_STATUSES,
    OFFER_STATUSES,
    CONTACT_APPLICATION_INTEGRATION_TYPE,
    TASK_TYPES,
    showAllItems: false,
  }),
  computed: {
    visibleActions() {
      return !this.showAllItems ? this.automation.automationProcedureActions.slice(0, 3) : this.automation.automationProcedureActions
    },
    visibleConditions() {
      return !this.showAllItems ? this.automation.automationProcedureConditions.slice(0, 3) : this.automation.automationProcedureConditions
    },
    getTypeAdditionalFields() {
      return this.automationsTriggerTypes.findWhere('value', this.automation.automationProcedureTrigger.type)?.additionalFields || []
    },
  },
  methods: {
    automationTriggerTypeByValue,
    automationCheckboxActionsByValue,
  },
}
</script>

<style lang="scss">
.hidden-content {
  position: relative;
  &::after {
    content: '';
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 70%);
    height: 65px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
