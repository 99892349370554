<template>
  <div>
    <!--    Buttons    -->
    <div
      class="mb-25 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Return    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>
    </div>

    <b-tabs>
      <b-tab :title="$t('Details')">
        <validation-observer
          ref="validator"
          class="observer"
        >
          <b-card
            class="px-2 py-50"
            no-body
          >
            <b-row>
              <b-col md="12">
                <!-- AUTOMATION NAME -->
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Name')"
                  rules="required"
                >
                  <b-form-group :label="$t('Name')">
                    <b-form-input
                      v-model="item.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <b-form-checkbox
                  v-model="item.active"
                  switch
                  inline
                  variant="primary"
                >
                  <span>{{ $t('Automation') }} {{ $t(item.active ? 'SessionActive' : 'SessionInactive').toLowerCase() }}</span>
                </b-form-checkbox>

              </b-col>
            </b-row>
          </b-card>

          <b-row>
            <b-col v-for="type in automationsTypes"
                   :key="`type_${type.value}`"
                   sm="12"
                   class="h-100"
                   md="6"
                   @click="setTypes(type)"
            >

              <b-card no-body
                      class="border cursor-pointer h-100"
                      :class="{ 'border-primary': item.type === type.value }"
              >
                <div class="d-flex justify-content-between align-items-center flex-wrap h-100">
                  <div class="d-flex p-1">
                    <b-avatar variant="primary"
                              class="mr-50"
                    >
                      <feather-icon size="20"
                                    :icon="type.icon"
                      />
                    </b-avatar>

                    <div>
                      <h5 class="mb-0">
                        {{ $t(type.label) }}
                      </h5>
                      <p class="text-muted mb-0">
                        {{ $t(type.description) }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>

          <b-card
            class="px-2 py-50"
            no-body
          >
            <div>
              <div v-if="item.type === 'CRON'">
                <b-row>
                  <!-- MONTH -->
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$tc('Months', 0)"
                    >
                      <sw-select :name="$tc('Months', 0)">
                        <v-select
                          v-model="cronMonth"
                          :options="time.month"
                          :state="errors.length > 0 ? false:null"
                          :reduce="type => type.value"
                        >
                          <template #option="option">
                            {{ $t(`${option.label}`) }}
                          </template>
                          <template #selected-option="option">
                            {{ $t(`${option.label}`) }}
                          </template>
                        </v-select>
                      </sw-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- DAY -->
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('DayOfTheMonth')"
                      rules="required"
                    >
                      <sw-select :name="$t('DayOfTheMonth')">
                        <v-select
                          v-model="cronDayOfMonth"
                          :options="time.dayOfMonth"
                          :state="errors.length > 0 ? false:null"
                          :reduce="type => type.value"
                        >
                          <template #option="option">
                            {{ typeof option.label === 'number' ? $tc('CurrentDayOfMonth', 1, { day: option.label }) : $t(`${option.label}`) }}
                          </template>
                          <template #selected-option="option">
                            {{ typeof option.label === 'number' ? $tc('CurrentDayOfMonth', 1, { day: option.label }) : $t(`${option.label}`) }}
                          </template>
                        </v-select>
                      </sw-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$tc('Days', 1)"
                      rules="required"
                    >
                      <sw-select :name="$tc('Days', 1)">
                        <v-select
                          v-model="cronDay"
                          :options="time.day"
                          :state="errors.length > 0 ? false:null"
                          :reduce="type => type.value"
                        >
                          <template #option="option">
                            {{ $t(`${option.label}`) }}
                          </template>
                          <template #selected-option="option">
                            {{ $t(`${option.label}`) }}
                          </template>
                        </v-select>
                      </sw-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- TIME -->
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('projects.time')"
                    >
                      <b-form-group :label="$t('projects.time')">
                        <b-form-timepicker
                          v-model="cronTime"
                          :locale="$i18n.locale"
                          :minutes-step="15"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col sm="6">
                    <automations-details-trigger
                      :item="item"
                      :application-types="applicationTypes"
                      :contact-statuses="contactStatuses"
                      :contact-thread-checklist-types-points="contactThreadChecklistTypesPoints"
                      :contact-thread-funnel-types-points="contactThreadFunnelTypesPoints"
                      :contact-thread-funnel-types="contactThreadFunnelTypes"
                      :contact-thread-order-statuses="contactThreadOrderStatuses"
                      :contact-thread-offer-statuses="contactThreadOfferStatuses"
                      :task-types="taskTypes"
                      @change="event => $set(item, event.field, event.value)"
                      @change-trigger="changeTrigger"
                    />
                  </b-col>

                  <b-col sm="6">
                    <sw-select :name="$t('RepeatType')"
                               :tip="$t('RepeatTypeDescription')"
                    >
                      <v-select
                        :key="123"
                        v-model="item.automationProcedureTrigger.repeatType"
                        :options="[
                          'ONCE',
                          'ALWAYS',
                        ]"
                        :clearable="false"
                      >
                        <template #selected-option="{ label }">
                          {{ $t(`${label}`) }}
                        </template>
                        <template #option="{ label }">
                          {{ $t(`${label}`) }}
                        </template>
                      </v-select>
                    </sw-select>
                  </b-col>

                  <b-overlay
                    :show="false"
                    no-wrap
                    variant="transparent"
                    :opacity="0.95"
                    :blur="'10px'"
                  >
                    <template #overlay>
                      <h4 class="text-primary text-center">
                        <feather-icon
                          icon="LockIcon"
                          size="20"
                        />

                        {{ $t('Soon') }}!
                      </h4>
                    </template>
                  </b-overlay>
                </b-row>
              </div>
              <!-- Action -->

              <div v-else-if="item.type === 'TRIGGER'">
                <automations-details-trigger
                  :item="item"
                  :application-types="applicationTypes"
                  :contact-statuses="contactStatuses"
                  :contact-thread-checklist-types-points="contactThreadChecklistTypesPoints"
                  :contact-thread-funnel-types-points="contactThreadFunnelTypesPoints"
                  :contact-thread-funnel-types="contactThreadFunnelTypes"
                  :contact-thread-order-statuses="contactThreadOrderStatuses"
                  :contact-thread-offer-statuses="contactThreadOfferStatuses"
                  :task-types="taskTypes"
                  @change="event => $set(item, event.field, event.value)"
                  @change-trigger="changeTrigger"
                />
              </div>
            </div>
          </b-card>
          <!-- CONDITIONS AND ACTIONS -->
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <h5 class="mb-0">
                    <feather-icon
                      icon="Share2Icon"
                      class="mr-25"
                      size="20"
                    />
                    <span>{{ $t('AdditionalConditions') }}
                      <b-badge variant="light-primary">{{ item.automationProcedureConditions.length || 0 }}</b-badge>
                    </span>
                  </h5>
                  <b-button
                    size="sm"
                    variant="primary"
                    :disabled="!getConditionTypesByAutomationType.length"
                    @click="editedCondition = { ...defaultCondition }; showEditCondition = true"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t('Add') }}
                  </b-button>
                </div>
                <b-alert
                  show
                  variant="primary"
                >
                  <div class="alert-body">
                    {{ $t('AdditionalConditionInfoBox') }}
                  </div>
                </b-alert>
              </div>
              <div>
                <!-- CONDITIONS BODY::END -->
                <app-timeline v-if="item.automationProcedureConditions.length">
                  <app-timeline-item
                    v-for="(condition, index) in item.automationProcedureConditions"
                    :key="`condition_${index}`"
                    variant="primary"
                  >
                    <b-card
                      no-body
                      class="p-1"
                    >
                      <automation-details-condition
                        :index="index"
                        :item="condition"
                        @edit="event => { editedCondition = { ...event, index }; showEditCondition = true }"
                        @delete="$delete(item.automationProcedureConditions, index)"
                      />
                    </b-card>
                  </app-timeline-item>
                </app-timeline>
                <div
                  v-else
                  class="p-50 text-center text-primary"
                >
                  <feather-icon icon="XCircleIcon" />
                  {{ $t('NoData') }}
                </div>
                <!-- CONDITIONS::END -->

                <!-- CONDITIONS MODAL: START -->
                <b-modal
                  v-model="showEditCondition"
                  no-close-on-backdrop
                  size="lg"
                  :title="$t(editedCondition.index > -1 ? 'Edit' : 'New')"
                  hide-footer
                  @hidden="editedCondition = { ...defaultCondition }; showEditCondition = false"
                >
                  <sw-select
                    :name="$t('task.Type')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedCondition.type"
                      :options="getConditionTypesByAutomationType"
                      :reduce="r => r.value"
                      @input="editedCondition.values = []"
                    >
                      <template #option="option">
                        {{ $t(`${option.label}`) }}
                      </template>
                      <template #selected-option="option">
                        {{ $t(`${option.label}`) }}
                      </template>
                    </v-select>
                  </sw-select>

                  <hr>
                  <!--            contactThreadFunnelTypePoints, contactStatuses  -->
                  <sw-select
                    v-if="editedCondition.type === CONTACT_STATUS"
                    :name="$t('ChooseStatus')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedCondition.contactStatuses"
                      label="name"
                      :options="contactStatuses"
                      multiple
                    />
                  </sw-select>

                  <sw-select
                    v-if="editedCondition.type === CONTACT_THREAD_FUNNEL_TYPE_POINT"
                    :name="$t('ChooseStatus')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedCondition.contactThreadFunnelTypePoints"
                      label="name"
                      :options="contactThreadFunnelTypesPoints"
                      multiple
                    />
                  </sw-select>

                  <div v-if="[CONTACT_ADDON_FIELD].includes(editedCondition.type)">
                    <sw-select
                      :name="$t('AddonField')"
                      class="flex-grow-1"
                    >
                      <v-select
                        v-model="editedCondition.contactAddonFieldType"
                        label="name"
                        :options="addonContactFields"
                      />
                    </sw-select>

                    <sw-select
                      :name="$t('Value')"
                      class="flex-grow-1"
                    >
                      <v-select
                        :value="editedCondition.values"
                        :options="time.addonContactFieldOptions"
                        @option:selected="editedCondition.values = [$event]"
                        @option:deselected="editedCondition.values = []"
                      >
                        <template #option="{ label, suffix }">
                          {{ $t(label) }} <span v-if="suffix"
                                                class="ml-25"
                          >{{ $t(suffix) }}</span>
                        </template>
                        <template #selected-option="{ label, suffix }">
                          {{ $t(label) }} <span v-if="suffix"
                                                class="ml-25"
                          >{{ $t(suffix) }}</span>
                        </template>
                      </v-select>
                    </sw-select>

                    <b-form-group>
                      <b-form-checkbox v-model="editedCondition.optionIgnoreYear"
                                       variant="primary"
                      >
                        {{ $t('IgnoreAge') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>

                  <sw-select
                    v-if="[CONTACT_THREAD_CART_OFFER_STATUS, CONTACT_APPLICATION_INTEGRATION_TYPE].includes(editedCondition.type)"
                    :name="$t('ChooseStatus')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedCondition.values"
                      label="name"
                      :options="getConditionOptionsByType"
                      :reduce="i => i.value"
                      multiple
                    >
                      <template #option="option">
                        {{ $tc(
                          editedCondition.type !== CONTACT_APPLICATION_INTEGRATION_TYPE
                            ? `offer.status.${option.label}`
                            : option.label, 1
                        ) }}
                      </template>
                      <template #selected-option="option">
                        {{ $tc(
                          editedCondition.type !== CONTACT_APPLICATION_INTEGRATION_TYPE
                            ? `offer.status.${option.label}`
                            : option.label, 1
                        ) }}
                      </template>
                    </v-select>
                  </sw-select>

                  <sw-select
                    v-if="[CONTACT_THREAD_FUNNEL_PROCESS_STATUS].includes(editedCondition.type)"
                    :name="$t('ChooseStatus')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedCondition.contactThreadFunnelProcessStatuses"
                      label="name"
                      :options="[{ value: 'OPEN', label: 'messageStatus.Open' }, { value: 'CLOSED_POSITIVE', label: 'funnel.status.COMPLETED' }, { value: 'CLOSED_NEGATIVE', label: 'funnel.status.REJECTED' }]"
                      :reduce="e => e.value"
                      multiple
                    >
                      <template #option="option">
                        {{ $tc(option.label) }}
                      </template>
                      <template #selected-option="option">
                        {{ $tc(option.label) }}
                      </template>
                    </v-select>
                  </sw-select>

                  <sw-select
                    v-if="[CONTACT_ASSIGNED_USER, CONTACT_THREAD_ASSIGNED_USER].includes(editedCondition.type)"
                    :name="$t('SelectUsers')"
                  >
                    <v-select
                      v-model="editedCondition.users"
                      :options="users"
                      label="firstName"
                      multiple
                      @search="searchUsers"
                    >
                      <template slot="no-options">
                        {{ $t('typeToSearchContact') }}
                      </template>
                      <template #option="{ id, firstName, lastName }">
                        <avatar :user="{id, firstName, lastName}" />
                      </template>

                      <template #selected-option="{ id, firstName, lastName }">
                        <avatar
                          text-variant="light"
                          :user="{id, firstName, lastName}"
                        />
                      </template>
                    </v-select>
                  </sw-select>
                  <!--              values-->
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="saveCondition"
                  >
                    {{ $t('Add') }}
                  </b-button>
                  <!--              users-->
                </b-modal>
                <!-- CONDITIONS MODAL: END -->
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <h5 class="mb-0">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-25"
                      size="20"
                    />
                    <span>{{ $t('Actions') }}
                      <b-badge variant="light-primary">{{ item.automationProcedureConditions.length || 0 }}</b-badge>
                    </span>
                  </h5>
                  <b-button
                    size="sm"
                    variant="primary"
                    :disabled="!getActionTypesByAutomationType.length"
                    @click="editedAction = { ...JSON.parse(JSON.stringify(defaultAction)) }; showEditAction = true"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t('Add') }}
                  </b-button>
                </div>
                <b-alert
                  show
                  variant="primary"
                >
                  <div class="alert-body">

                    {{ $t('ActionInfoBox') }}
                  </div>
                </b-alert>
              </div>
              <div>
                <!-- ACTIONS BODY::END -->
                <app-timeline v-if="item.automationProcedureActions.length">
                  <app-timeline-item
                    v-for="(action, index) in item.automationProcedureActions"
                    :key="`action_${index}`"
                    variant="primary"
                  >
                    <b-card
                      no-body
                      class="p-1"
                    >
                      <automation-details-action
                        :index="index"
                        :item="action"
                        @edit="editAction"
                        @delete="deleteAction(index, action)"
                        @add-nested-action="(e) => { addNested({ parentIndex: index, index: e }) }"
                      />
                      <!--                  $delete(item.automationProcedureActions, index) -->
                    </b-card>
                  </app-timeline-item>
                </app-timeline>
                <div
                  v-else
                  class="p-50 text-center text-primary"
                >
                  <feather-icon icon="XCircleIcon" />
                  {{ $t('NoData') }}
                </div>
                <!-- ACTIONS::END -->

                <!-- ACTIONS MODAL: START -->
                <b-modal
                  v-model="showEditAction"
                  no-close-on-backdrop
                  size="lg"
                  :title="$t(editedAction.index > -1 ? 'Edit' : 'New')"
                  hide-footer
                  @hidden="editedAction = { ...JSON.parse(JSON.stringify(defaultAction)) }; showEditAction = false"
                >
                  <sw-select
                    :name="$t('task.Type')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedAction.type"
                      :options="getActionTypesByAutomationType"
                      :reduce="r => r.value"
                      @input="editedAction.subject = ''; editedAction.content = ''; editedAction.template = ''"
                    >
                      <template #option="option">
                        {{ $t(`${option.label}`) }}
                      </template>
                      <template #selected-option="option">
                        {{ $t(`${option.label}`) }}
                      </template>
                    </v-select>
                  </sw-select>

                  <!-- CHANGE CONTACT STATUS -->
                  <sw-select
                    v-if="editedAction.type === CHANGE_CONTACT_STATUS"
                    :name="$t('ChangeTo')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedAction.contactStatus"
                      label="name"
                      :options="contactStatuses"
                    />
                  </sw-select>

                  <!-- CHANGE CONTACT  STATUS :: END -->
                  <!-- CHANGE CONTACT THREAD STATUS -->
                  <sw-select
                    v-if="editedAction.type === CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT"
                    :name="$t('ChangeTo')"
                    class="flex-grow-1"
                  >
                    <v-select
                      v-model="editedAction.contactThreadFunnelTypePoint"
                      label="name"
                      :options="contactThreadFunnelTypesPoints"
                    />
                  </sw-select>

                  <!-- CHANGE CONTACT THREAD STATUS :: END -->
                  <!-- ASSIGNED USERS -->
                  <sw-select
                    v-if="[ASSIGN_USER_CONTACT, ASSIGN_USER_CONTACT_THREAD].includes(editedAction.type)"
                    :name="$t('SelectUsers')"
                  >
                    <v-select
                      v-model="editedAction.users"
                      :options="users"
                      label="firstName"
                      multiple
                      @search="searchUsers"
                    >
                      <template slot="no-options">
                        {{ $t('typeToSearchContact') }}
                      </template>
                      <template #option="{ id, firstName, lastName }">
                        <avatar :user="{id, firstName, lastName}" />
                      </template>

                      <template #selected-option="{ id, firstName, lastName }">
                        <avatar
                          text-variant="light"
                          :user="{id, firstName, lastName}"
                        />
                      </template>
                    </v-select>
                  </sw-select>
                  <!-- ASSIGNED USERS :: END -->
                  <!-- SEND SMS, EMAIL -->
                  <template v-if="[SEND_SMS, SEND_EMAIL].includes(editedAction.type)">
                    <b-form-group
                      v-if="[SEND_EMAIL].includes(editedAction.type)"
                      :label="$t('Title')"
                    >
                      <b-form-input v-model="editedAction.subject" />
                    </b-form-group>
                    <sw-select :name="$t('SendTo')">
                      <v-select
                        v-model="editedAction.users"
                        :options="users"
                        label="firstName"
                        multiple
                        :selectable="option => isActionEmailType ? option.email : option.phone"
                        @search="searchUsers"
                      >
                        <template slot="no-options">
                          {{ $t('typeToSearchContact') }}
                        </template>
                        <template #option="{ id, firstName, lastName, phone, email }">
                          <avatar :user="{id, firstName, lastName}">
                            <template #subtitle>
                              <span v-if="isActionEmailType">{{ email || '-' }}</span>
                              <span v-else>{{ phone || '-' }}</span>
                            </template>
                          </avatar>
                        </template>

                        <template #selected-option="{ id, firstName, lastName, phone, email }">
                          <avatar
                            :user="{id, firstName, lastName}"
                            text-variant="light"
                          >
                            <template #subtitle>
                              <span v-if="isActionEmailType">{{ email || '-' }}</span>
                              <span v-else>{{ phone || '-' }}</span>
                            </template>
                          </avatar>
                        </template>
                      </v-select>
                    </sw-select>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="editedAction.sendToContact"
                        variant="primary"
                      >
                        {{ $t('SendToContact') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <sw-select
                      v-if="[SEND_SMS].includes(editedAction.type)"
                      :name="$t('ShareTo')"
                    >
                      <v-select
                        v-model="editedAction.sharedRanks"
                        multiple
                        :close-on-select="false"
                        :options="ranks"
                        label="name"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <sw-select
                      :name="$t('Template')"
                      class="mb-1"
                    >
                      <v-select
                        v-model="editedAction.template"
                        label="name"
                        :options="isActionEmailType ? emailTemplates : smsTemplates"
                        :reduce="template => template.content"
                        @input="setContent"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <div class="mb-50">
                      <label for="content">{{ $t('MessageContent') }}</label>
                      <div v-if="!isActionEmailType">
                        <b-form-textarea
                          id="content"
                          v-model="editedAction.content"
                          :state="editedAction.content.length <= actionContentMaxLength ? (editedAction.content.length > 0 ? true : null) : false"
                          class="char-textarea"
                          :aria-invalid="false"
                          :placeholder="$t('MessageContent')"
                          rows="5"
                        />
                        <div class="d-flex justify-content-between align-content-start align-items-start mt-25">
                          <b-button
                            id="custom-button"
                            variant="success"
                            class="w-auto"
                            style="padding: 3px 5px"
                            @click.prevent.stop="isShortcodesModalOpen = true"
                          >
                            <small class="text-white">
                              <feather-icon
                                icon="CodeIcon"
                                class="mr-25"
                              />
                            </small>

                            <small class="text-white">
                              {{ $t('ShortCodes') }}
                            </small>
                          </b-button>
                          <b-badge
                            variant="primary"
                            :class="editedAction.content.length >= actionContentMaxLength ? 'bg-danger' : ''"
                          >
                            <span class="char-count">{{ editedAction.content.length }}</span> / {{ actionContentMaxLength }}
                          </b-badge>
                        </div>
                      </div>
                      <div
                        v-else
                        class="message-editor"
                      >
                        <quill-editor
                          id="quil-content"
                          v-model="editedAction.content"
                          style="height: 200px"
                          :options="{ ...editorOption, rows: 10 }"
                        />
                        <!--        <div style="font-size: 13px; padding: 12px 15px" :inner-html.prop="footer | shortcodePreview()" />-->
                        <div
                          id="quill-toolbar"
                          class="d-flex justify-content-between w-100"
                        >
                          <!-- Add a bold button -->
                          <div class="flex-grow-1">
                            <button class="ql-bold" />
                            <button class="ql-italic" />
                            <button class="ql-underline" />
                            <button class="ql-link" />

                            <span class="ql-formats">
                              <b-button
                                id="custom-button"
                                variant="success"
                                class="w-auto"
                                @click.prevent.stop="isShortcodesModalOpen = true"
                              >
                                <small class="text-white">
                                  <feather-icon
                                    icon="CodeIcon"
                                    class="mr-25"
                                  />
                                </small>

                                <small class="text-white">
                                  {{ $t('ShortCodes') }}
                                </small>
                              </b-button>
                            </span>
                          </div>

                          <small
                            class="textarea-counter-value rounded d-flex align-items-center float-right"
                            style="justify-self: end"
                            :class="editedAction.content.length >= 100000 ? 'bg-danger' : ''"
                          >
                            <span class="char-count">{{ editedAction.content.length }} </span> / 100000
                          </small>
                        </div>
                      </div>
                      <DragDropUpload
                        ref="dragdropupload"
                        :is-refresh="false"
                        :default-value="editedAction.files"
                        @onChangeFiles="editedAction.files = [...editedAction.files]"
                      />
                    </div>
                  </template>
                  <!-- SEND SMS, EMAIL :: END -->
                  <!-- CREATE TASK :: START -->
                  <template v-if="editedAction.type === CREATE_CONTACT_THREAD_TASK">
                    <sw-select
                      :name="$t('SelectTaskType')"
                      class="mt-1"
                    >
                      <v-select
                        id="task-type"
                        v-model="editedAction.automationProcedureActionContactThreadTask.contactThreadTaskType"
                        :options="taskTypes"
                        item-value="id"
                        item-text="name"
                        label="name"
                        :clearable="false"
                        @input="checkRequireds($event)"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>

                    <!--    Task content-->
                    <div
                      v-if="requiredTaskFields.isContentRequired"
                      class="mt-50"
                    >
                      <label for="task-content">{{ $t('TaskContent') }}</label>
                      <b-form-textarea
                        id="task-content"
                        v-model="editedAction.automationProcedureActionContactThreadTask.content"
                        rows="5"
                      />
                    </div>
                    <!--    Google address -->
                    <div
                      v-if="requiredTaskFields.isAddressRequired"
                      class="mt-50"
                    >
                      <label for="task-address">{{ $t('Address') }}</label>
                      <b-form-input
                        id="task-address"
                        v-model="editedAction.automationProcedureActionContactThreadTask.address"
                      />
                    </div>

                    <div class="mt-50">
                      <b-form-group :label="$t('DaysAfter')">
                        <b-form-input
                          v-model="editedAction.automationProcedureActionContactThreadTask.daysAfter"
                          type="number"
                        />
                      </b-form-group>

                      <b-form-group :label="$t('TaskTime')">
                        <flat-pickr
                          v-model="editedAction.automationProcedureActionContactThreadTask.time"
                          class="form-control"
                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true}"
                        />
                      </b-form-group>
                    </div>

                    <!--    Task time -->
                    <!--              <b-form-checkbox-->
                    <!--                v-model="editedAction.automationProcedureActionContactThreadTask.isWholeDay"-->
                    <!--                variant="primary"-->
                    <!--                class="custom-control-primary mt-2"-->
                    <!--                @change="onChangeWholeDay"-->
                    <!--              >-->
                    <!--                {{ $t('WholeDay') }}-->
                    <!--              </b-form-checkbox>-->
                    <!--              <b-row>-->
                    <!--                <b-col :cols="editedAction.automationProcedureActionContactThreadTask.isWholeDay ? 12 : 6">-->
                    <!--                  <b-form-group :label="editedAction.automationProcedureActionContactThreadTask.isWholeDay ? $t('TaskTerm') : $t('TaskStartTerm')">-->
                    <!--                    <flat-pickr-->
                    <!--                      :key="`time_${editedAction.automationProcedureActionContactThreadTask.isWholeDay}`"-->
                    <!--                      v-model="editedAction.automationProcedureActionContactThreadTask.startAt"-->
                    <!--                      class="form-control"-->
                    <!--                      :config="{ ...timepicker, enableTime: !editedAction.automationProcedureActionContactThreadTask.isWholeDay, locale: getCalendarLocale($i18n.locale), maxDate: editedAction.automationProcedureActionContactThreadTask.endAt ? editedAction.automationProcedureActionContactThreadTask.endAt : '', dateFormat: editedAction.automationProcedureActionContactThreadTask.isWholeDay ? 'Y-m-d' : 'Y-m-d H:i' }"-->
                    <!--                    />-->
                    <!--                  </b-form-group>-->
                    <!--                </b-col>-->
                    <!--                <b-col-->
                    <!--                  v-if="!editedAction.automationProcedureActionContactThreadTask.isWholeDay"-->
                    <!--                  cols="6"-->
                    <!--                >-->
                    <!--                  <b-form-group :label="editedAction.automationProcedureActionContactThreadTask.isWholeDay ? $t('TaskTerm') : $t('TaskEndTerm')">-->
                    <!--                    <flat-pickr-->
                    <!--                      :key="`time_end_${editedAction.automationProcedureActionContactThreadTask.isWholeDay}`"-->
                    <!--                      v-model="editedAction.automationProcedureActionContactThreadTask.endAt"-->
                    <!--                      class="form-control"-->
                    <!--                      :config="{ ...timepicker, enableTime: !editedAction.automationProcedureActionContactThreadTask.isWholeDay, locale: getCalendarLocale($i18n.locale), dateFormat: editedAction.automationProcedureActionContactThreadTask.isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: editedAction.automationProcedureActionContactThreadTask.startAt ? editedAction.automationProcedureActionContactThreadTask.startAt : '' }"-->
                    <!--                    />-->
                    <!--                  </b-form-group>-->
                    <!--                </b-col>-->
                    <!--              </b-row>-->
                    <!-- Task for -->
                    <div class="mt-1">
                      <b-form-checkbox
                        v-model="editedAction.automationProcedureActionContactThreadTask.assignedLeadUser"
                        switch
                        inline
                        variant="primary"
                        @input="e => { if (e) { editedAction.automationProcedureActionContactThreadTask.assignedUser = null } }"
                      >
                        <span>{{ $t('PrimaryUser') }}</span>
                      </b-form-checkbox>
                    </div>

                    <div class="mt-1">
                      <sw-select :name="$t('AssignedUser')">
                        <assigned-users
                          :disabled="editedAction.automationProcedureActionContactThreadTask.assignedLeadUser"
                          :value="editedAction.automationProcedureActionContactThreadTask.assignedUser"
                          :is-multiple="false"
                          :is-clearable="false"
                          @input="editedAction.automationProcedureActionContactThreadTask.assignedUser = $event"
                        />
                      </sw-select>
                    </div>
                  </template>
                  <!-- CREATE TASK :: END -->
                  <!-- CREATE NOTE :: START -->
                  <template v-if="editedAction.type === CREATE_CONTACT_THREAD_NOTE">
                    <sw-select
                      :name="$t('SelectNoteType')"
                    >
                      <v-select
                        v-model="editedAction.automationProcedureActionContactThreadNote.contactThreadNoteType"
                        :options="noteTypes"
                        label="name"
                        :clearable="false"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name, icon }">
                          <feather-icon
                            :icon="icon"
                            size="16"
                            class="align-middle mr-50"
                          />
                          <span>{{ name }}</span>
                        </template>
                      </v-select>
                    </sw-select>

                    <b-form-group :label="$t('NoteContent')">
                      <b-form-textarea
                        id="note-content"
                        v-model="editedAction.automationProcedureActionContactThreadNote.content"
                        rows="5"
                        :placeholder="$t('NoteContent')"
                      />
                    </b-form-group>

                    <sw-select :name="$t('ShareNote')">
                      <v-select
                        v-model="editedAction.automationProcedureActionContactThreadNote.sharedRanks"
                        multiple
                        :close-on-select="false"
                        :options="ranks"
                        label="name"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                  </template>
                  <!-- CREATE NOTE :: END -->
                  <!-- CREATE CHECKLIST :: START -->
                  <template v-if="editedAction.type === CREATE_CONTACT_THREAD_CHECKLIST">
                    <sw-select :name="$t('SelectChecklist')">
                      <v-select
                        v-model="editedAction.automationProcedureActionContactThreadChecklistTypes"
                        :options="contactThreadChecklistTypes"
                        label="name"
                        multiple
                        :clearable="false"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                        <template #option="{ name, icon }">
                          <feather-icon
                            :icon="icon"
                            size="16"
                            class="align-middle mr-50"
                          />
                          <span>{{ name }}</span>
                        </template>
                      </v-select>
                    </sw-select>
                  </template>
                  <!-- CREATE CHECKLIST :: END -->
                  <!-- CREATE CONTACT :: START -->
                  <template v-if="editedAction.type === CREATE_CONTACT_WITH_CONTACT_THREAD">
                    <b-row>
                      <b-col sm="12">
                        <div class="divider text-primary">
                          <div class="divider-text">
                            {{ $t('Contact') }}
                          </div>
                        </div>
                      </b-col>
                      <b-col md="7">
                        <sw-select :name="$t('contact.contactStatus')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContact.status"
                            :options="contactStatuses"
                            label="name"
                          >
                            <template #no-options>
                              {{ $t('NoOptions') }}
                            </template>
                          </v-select>
                        </sw-select>
                      </b-col>
                      <b-col md="5">
                        <sw-select :name="$t('Language')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContact.locale"
                            :reduce="item => item.value"
                            :options="[
                              { value: 'pl_pl', text: 'Polski' },
                              { value: 'en_uk', text: 'Angielski' },
                            ]"
                            label="text"
                            item-value="value"
                          />
                        </sw-select>
                      </b-col>
                      <b-col md="12">
                        <b-form-group :label="$t('Note')">
                          <b-form-textarea v-model="editedAction.automationProcedureActionContact.note" />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <sw-select :name="$t('Tags')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContact.contactTypes"
                            multiple
                            :options="tags"
                            label="name"
                          >
                            <template #no-options>
                              {{ $t('NoOptions') }}
                            </template>
                            <template #option="{ name, color }">
                              <div
                                class="color-dot"
                                style="border: 1px solid white"
                                :style="{ backgroundColor: color }"
                              />
                              {{ name }}
                            </template>
                            <template #selected-option="{ name, color }">
                              <div
                                class="color-dot"
                                style="border: 1px solid white"
                                :style="{ backgroundColor: color }"
                              />
                              {{ name }}
                            </template>
                          </v-select>
                        </sw-select>
                      </b-col>
                      <b-col md="12">
                        <sw-select :name="$t('contact.group')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContact.userGroups"
                            :options="groups"
                            label="name"
                            multiple
                          >
                            <template #no-options>
                              {{ $t('NoOptions') }}
                            </template>
                          </v-select>
                        </sw-select>
                      </b-col>
                      <!-- Thread -->
                      <b-col sm="12">
                        <div class="divider text-primary">
                          <div class="divider-text">
                            {{ $t('Thread') }}
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <b-form-group :label="$t('Name')">
                          <b-form-input v-model="editedAction.automationProcedureActionContactThread.name" />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <sw-select :name="$t('contact.users')">
                          <assigned-users
                            class="mb-0"
                            :value="editedAction.automationProcedureActionContact.assignedUsers"
                            @input="editedAction.automationProcedureActionContact.assignedUsers = $event"
                          />
                        </sw-select>
                      </b-col>
                      <b-col md="12">
                        <sw-select :name="$t('AutomationSection.ContactThreadFunnelTypePoint')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContactThread.contactThreadFunnelTypePoint"
                            label="name"
                            :options="contactThreadFunnelTypesPoints"
                          />
                        </sw-select>
                      </b-col>
                      <b-col md="12">
                        <sw-select :name="$t('contactTabs.Checklist')">
                          <v-select
                            v-model="editedAction.automationProcedureActionContactThread.contactThreadChecklistTypes"
                            label="name"
                            :options="contactThreadChecklistTypes"
                            multiple
                          />
                        </sw-select>
                      </b-col>
                    </b-row>
                  </template>
                  <!-- CREATE CONTACT :: END -->
                  <!--              values-->
                  <b-button
                    variant="primary"
                    size="sm"
                    :disabled="!editedAction.type"
                    @click="saveAction"
                  >
                    {{ $t('Add') }}
                  </b-button>
                  <!--              users-->
                </b-modal>
                <!-- CONDITIONS MODAL: END -->
              </div>
            </b-col>
          </b-row>

          <!--    Modal: Shortcode     -->
          <b-modal
            :visible="isShortcodesModalOpen"
            :title="$t('Codes')"

            size="lg"
            hide-footer
            no-close-on-backdrop
            @hide="isShortcodesModalOpen = false"
          >
            <shortcodes-modal
              :default-shortcodes="automationsShortcodes"
              @close-modal="isShortcodesModalOpen = false"
            />
          </b-modal>

          <b-button
            variant="primary"
            @click="saveAutomation"
          >
            {{ $t('Save') }}
          </b-button>

          <b-overlay
            :show="loading"
            no-wrap
            spinner-variant="primary"
          />
        </validation-observer>
      </b-tab>

      <b-tab :title="$t('EventLog')"
             :disabled="!item.id"
      >
        <automation-logs v-if="item.id"
                         :automation-id="item.id"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BFormTextarea, BFormTimepicker, BTab, BTabs,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { reactive, ref } from '@vue/composition-api'
import { required } from '@validations'
import {
  ASSIGN_USER_CONTACT,
  ASSIGN_USER_CONTACT_THREAD,
  automationsActionTypes,
  automationsConditionTypes,
  automationsTriggerTypes,
  automationsTypes, automationTriggerTypeByValue,
  CHANGE_CONTACT_STATUS,
  CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT,
  CONTACT_APPLICATION_INTEGRATION_TYPE,
  CONTACT_ASSIGNED_USER,
  CONTACT_STATUS,
  CONTACT_THREAD_FUNNEL_PROCESS_STATUS,
  CONTACT_THREAD_ASSIGNED_USER,
  CONTACT_THREAD_CART_OFFER_STATUS,
  CONTACT_ADDON_FIELD,
  CONTACT_THREAD_FUNNEL_TYPE_POINT, CREATE_CONTACT_THREAD_CHECKLIST,
  CREATE_CONTACT_THREAD_NOTE,
  CREATE_CONTACT_THREAD_TASK,
  CREATE_CONTACT_WITH_CONTACT_THREAD,
  SEND_EMAIL,
  SEND_SMS,
} from '@/@types/automationsTypes'
import AutomationsDetailsTrigger from '@/views/settings/automations/components/AutomationsDetailsTrigger.vue'
import axiosIns from '@/libs/axios'
import AutomationDetailsAction from '@/views/settings/automations/components/AutomationDetailsAction.vue'
import AutomationDetailsCondition from '@/views/settings/automations/components/AutomationDetailsCondition.vue'
import { USERS_SEARCH } from '@/@constants/fields'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import {
  GET_FOOTER,
  GET_GROUPS,
  GET_NOTES,
  GET_TASKS,
  GET_TEMPLATES,
  GET_THREAD_TAGS,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { quillEditor } from 'vue-quill-editor'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
// import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
// import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
import { automationsShortcodes } from '@/@data/shortcodes'
import AutomationLogs from '@/pages/settings/AutomationLogs.vue'
import moment from 'moment'

const defaultCondition = {
  type: '',
  contactAddonFieldType: '',
  optionIgnoreYear: false,
  contactStatuses: [],
  contactThreadFunnelTypePoints: [],
  // offer, order, applications types (constants)
  values: [],
  users: [],
  contactThreadFunnelProcessStatuses: [],
}

const defaultAction = {
  type: '',
  contactStatus: '',
  contactThreadFunnelTypePoint: '',
  users: [],
  files: [],
  automationProcedureActionContactThreadChecklistTypes: [],
  sharedRanks: [],
  sendToContact: false,
  content: '',
  subject: '',
  template: '',
  automationProcedureActionContactThreadTask: {
    daysAfter: 0,
    time: '',
    contactThreadTaskType: '',
    content: '',
    address: '',
    assignedUser: '',
    isWholeDay: false,
    assignedLeadUser: false,
  },
  automationProcedureActionContactThreadNote: {
    content: '',
    contactThreadNoteType: '',
    sharedRanks: [],
  },
  automationProcedureActionContact: {
    status: '',
    note: '',
    userGroups: [],
    locale: 'pl_pl',
    contactTypes: [],
    assignedUsers: [],
  },
  automationProcedureActionContactThread: {
    assignedUsers: [],
    name: '',
    contactThreadFunnelTypePoint: '',
    contactThreadChecklistTypes: '',
    additionalAutomationProcedureActions: [],
  },
}

export default {
  name: 'AutomationsDetails',
  components: {
    ShortcodesModal,
    DragDropUpload,
    AssignedUsers,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    AutomationDetailsCondition,
    AutomationDetailsAction,
    AutomationsDetailsTrigger,
    vSelect,
    BTab,
    BTabs,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    flatPickr,
    AutomationLogs,
  },
  data: () => ({
    isShortcodesModalOpen: false,
    automationsShortcodes,
    loading: true,

    CONTACT_STATUS,
    CONTACT_THREAD_FUNNEL_TYPE_POINT,
    CONTACT_THREAD_ASSIGNED_USER,
    CONTACT_ASSIGNED_USER,
    CONTACT_THREAD_CART_OFFER_STATUS,
    CONTACT_APPLICATION_INTEGRATION_TYPE,
    CONTACT_ADDON_FIELD,

    CONTACT_THREAD_FUNNEL_PROCESS_STATUS,

    SEND_SMS,
    SEND_EMAIL,
    CREATE_CONTACT_WITH_CONTACT_THREAD,
    CREATE_CONTACT_THREAD_TASK,
    CREATE_CONTACT_THREAD_NOTE,
    CHANGE_CONTACT_STATUS,
    CHANGE_CONTACT_THREAD_FUNNEL_TYPE_POINT,
    ASSIGN_USER_CONTACT,
    ASSIGN_USER_CONTACT_THREAD,
    CREATE_CONTACT_THREAD_CHECKLIST,

    editorOption: {
      modules: {
        toolbar: '#quill-toolbar',
      },
    },

    props: {
      automationCopyId: {
        required: false,
        default: null,
      },
    },
    addonContactFieldOptions: [
      { value: 'now', label: 'IsToday' },
      ...(new Array(10)).fill(null).map((e, i) => ({ value: `+${i + 1} day`, label: i + 1, suffix: 'AutomationDaysBefore' })),
      ...(new Array(10)).fill(null).map((e, i) => ({ value: `-${i + 1} day`, label: i + 1, suffix: 'AutomationDaysAfter' })),
    ],
    item: {
      name: '',
      type: 'TRIGGER',
      active: true,
      automationProcedureActions: [],
      automationProcedureConditions: [],
      automationProcedureTrigger: {
        type: '',
        repeatType: 'ONCE',
        contactStatusFrom: '',
        contactThreadFunnelTypeFrom: '',
        contactThreadFunnelTypeTo: '',
        contactStatusTo: '',
        contactThreadFunnelTypePointTo: '',
        contactThreadFunnelTypePointFrom: '',
        contactThreadChecklistTypePoint: '',
        contactThreadChecklistPointAction: 'ALL',
        contactThreadOrderStatusTo: '',
        contactThreadOrderStatusFrom: '',
        contactThreadOfferStatusTo: '',
        contactThreadOfferStatusFrom: '',
        webhookType: '',
        integrationFacebookWebhook: '',
        contactApplicationIntegration: '',
        contactThreadTaskTypes: [],
        contactThreadTaskStatusTo: '',
      },
    },

    cronDay: '1-7',
    cronMonth: '1-12',
    cronDayOfMonth: '1-L',
    cronTime: '12:00',

    defaultCondition,
    showEditCondition: false,
    editedCondition: {
      ...defaultCondition,
    },

    defaultAction,
    showEditAction: false,
    editedAction: {
      ...defaultAction,
    },

    required,
    smsTemplates: [],
    emailTemplates: [],
    contactStatuses: [],
    contactThreadChecklistTypesPoints: [],
    contactThreadChecklistTypes: [],
    contactThreadFunnelTypesPoints: [],
    contactThreadFunnelTypes: [],
    contactThreadOrderStatuses: [
      { value: 'NEW', label: 'NEW' },
      { value: 'IN_REALIZATION', label: 'IN_REALIZATION' },
      { value: 'SENT', label: 'SENT' },
      { value: 'DELIVERED', label: 'DELIVERED' },
      { value: 'REALIZED', label: 'REALIZED' },
      { value: 'CANCELLED', label: 'CANCELLED' },
    ],
    contactThreadOfferStatuses: [
      { value: 'NEW', label: 'NEW' },
      { value: 'CANCELLED', label: 'CANCELLED' },
      { value: 'ACCEPTED', label: 'ACCEPTED' },
      { value: 'EXPIRED', label: 'EXPIRED' },
    ],
    applicationTypes: [
      { label: 'Google', value: 'GOOGLE_ADS' },
      { label: 'HTML', value: 'HTML' },
      { label: 'Wordpress', value: 'WORDPRESS' },
      { label: 'Facebook', value: 'FACEBOOK' },
    ],
    users: [],
    footer: '',
    //   Task
    taskTypes: [],
    // timepicker: {
    //   enableTime: true,
    //   dateFormat: 'Y-m-d H:i',
    //   time_24hr: true,
    //   locale: [pl, en],
    // },
    requiredTaskFields: {
      isContentRequired: false,
      isAddressRequired: false,
      isReminderActive: false,
    },
    // Notes
    noteTypes: [],
    addonContactFields: [],
    ranks: [],
    // Contact
    groups: [],
    tags: [],
  }),
  computed: {
    getConditionOptionsByType() {
      return this.editedCondition.type === this.CONTACT_APPLICATION_INTEGRATION_TYPE
        ? this.applicationTypes : this.contactThreadOfferStatuses
    },
    getConditionTypesByAutomationType() {
      if (!this.item.automationProcedureTrigger.type) return []

      const automationType = automationsTriggerTypes.findWhere('value', this.item.automationProcedureTrigger.type)
      if (!automationType) return []

      return automationsConditionTypes.filter(condition => automationType.conditions.includes(condition.value))
    },
    getActionTypesByAutomationType() {
      if (this.editedAction.index?.parentIndex >= 0) {
        return automationsActionTypes.filter(action => [
          SEND_SMS,
          SEND_EMAIL,
          CREATE_CONTACT_THREAD_TASK,
          CREATE_CONTACT_THREAD_NOTE,
        ].includes(action.value))
      }

      if (!this.item.automationProcedureTrigger.type) return []

      const automationType = automationsTriggerTypes.findWhere('value', this.item.automationProcedureTrigger.type)
      if (!automationType) return []

      return automationsActionTypes.filter(action => automationType.actions.includes(action.value))
    },

    isActionEmailType() {
      return this.editedAction.type === this.SEND_EMAIL
    },
    actionContentMaxLength() {
      return this.isActionEmailType ? 100000 : 400
    },
  },
  watch: {
    // 'item.automationProcedureTrigger.type': function (newValue, oldValue) {
    //   if (newValue && oldValue && newValue !== oldValue) {
    //     if (this.item.automationProcedureActions.length || this.item.automationProcedureConditions.length) {
    //
    //     }
    //   }
    // },
    editedAction: {
      deep: true,
      handler(newValue) {
        //   Task
        if (newValue.type === this.CREATE_CONTACT_THREAD_TASK) {
          if (!newValue.automationProcedureActionContactThreadTask) {
            this.editedAction.automationProcedureActionContactThreadTask = { ...this.defaultAction.automationProcedureActionContactThreadTask }
          } else {
            this.checkRequireds(newValue.automationProcedureActionContactThreadTask.contactThreadTaskType || {})
            // if (typeof newValue.automationProcedureActionContactThreadTask.isWholeDay !== 'boolean') {
            //   this.editedAction.automationProcedureActionContactThreadTask.isWholeDay = (newValue.endAt && newValue.endAt.date.includes('23:59:59')) && (newValue.startAt && newValue.startAt.date.includes('00:00:00'))
            // }
          }
        }

        // Note
        if (newValue.type === this.CREATE_CONTACT_THREAD_NOTE) {
          if (!newValue.automationProcedureActionContactThreadNote) {
            this.editedAction.automationProcedureActionContactThreadNote = { ...JSON.parse(JSON.stringify(this.defaultAction.automationProcedureActionContactThreadNote)) }
          }
        }

        // Contact
        if (newValue.type === this.CREATE_CONTACT_WITH_CONTACT_THREAD) {
          if (!newValue.automationProcedureActionContact) {
            this.editedAction.automationProcedureActionContact = { ...JSON.parse(JSON.stringify(this.defaultAction.automationProcedureActionContact)) }
          }

          if (!newValue.automationProcedureActionContactThread) {
            this.editedAction.automationProcedureActionContactThread = { ...JSON.parse(JSON.stringify(this.defaultAction.automationProcedureActionContactThread)) }
          }
        }
      },
    },
    '$route.params.automationId': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.loadAutomation()
      },
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.loadChecklists(),
      this.loadThreadStatuses(),
      this.loadContactStatuses(),
      this.loadTemplates(),
      this.loadFooter(),
      this.loadTaskTypes(),
      this.loadNoteTypes(),
      this.loadRanks(),
      this.loadGroups(),
      this.loadTags(),
      this.loadAddonContactFields(),
    ])

    await this.loadAutomation()
  },
  methods: {
    async deleteAction(index, { id = null }) {
      if (id) {
        // request
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(async () => {
            try {
              await axiosIns.delete('1/automations/procedures/actions', { data: JSON.stringify([id]) })
              this.$delete(this.item.automationProcedureActions, index)
            } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            }
          })
      } else {
        this.$delete(this.item.automationProcedureActions, index)
      }
    },
    editAction(event) {
      this.editedAction = { ...event }
      this.showEditAction = true
    },
    addNested(a) {
      this.editedAction = { ...JSON.parse(JSON.stringify(this.defaultAction)), index: a }
      this.showEditAction = true
    },
    checkRequireds({ isAddressRequired, isContentRequired, isReminderActive }) {
      this.requiredTaskFields = { isAddressRequired, isContentRequired, isReminderActive }
    },
    setTypes(type) {
      if (!this.item.id) {
        this.item.type = type.value
        this.item.automationProcedureTrigger.type = ''
      }
    },
    loadTags() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
          .then(res => {
            this.tags = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
            reject()
          })
      })
    },
    loadGroups() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`groups/${GET_GROUPS}`)
          .then(res => {
            this.groups = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
            reject()
          })
      })
    },
    loadNoteTypes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`typeSettings/${GET_NOTES}`)
          .then(noteTypes => {
            this.noteTypes = noteTypes
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    loadRanks() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('ranks/GET_RANKS_LIST')
          .then(ranks => {
            this.ranks = ranks
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    loadTaskTypes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`typeSettings/${GET_TASKS}`, { gte_id: '1001' })
          .then(taskTypes => {
            this.taskTypes = taskTypes
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    loadFooter() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`templates/${GET_FOOTER}`)
          .then(res => {
            this.footer = res.data.item.emailFooter
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    async loadAddonContactFields() {
      try {
        const resp = await axiosIns.get('1/settings/contactAddonFieldsTypes', { params: { fields_load: this.$fields.CONTACT_ADDON_FIELD_TYPES, orderBy: 'position.asc', eq_type: 'DATE' } })

        if (resp?.data?.data?.items) {
          this.addonContactFields = (resp.data.data.items || [])
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async removeAutomationActionsByTriggerAllowedTypes(allowedActions = []) {
      const toDeleteIds = []
      const toDeleteIndex = []

      this.item.automationProcedureActions.forEach((action, index) => {
        const { id, type } = action

        if (!allowedActions.includes(type)) {
          if (id) toDeleteIds.push(id)

          toDeleteIndex.push(index)
        }
      })

      try {
        if (toDeleteIds.length) await axiosIns.delete('1/automations/procedures/actions', { data: JSON.stringify(toDeleteIds) })

        toDeleteIndex.forEach(index => this.$delete(this.item.automationProcedureActions, index))
        return [{ toDeleteIds, toDeleteIndex }, null]
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return [null, err]
      }
    },
    async changeTrigger(event) {
      if (this.item.automationProcedureActions.length && event.field === 'type') {
        const trigger = automationTriggerTypeByValue(event.value)
        const { actions: allowedActions } = trigger

        const isAccepted = await this.showAlert('warning', '', this.$i18n.t('SelectedTriggerCantUseSelectedActions'))
        if (!isAccepted) return

        await this.removeAutomationActionsByTriggerAllowedTypes(allowedActions)
      }

      this.$set(this.item.automationProcedureTrigger, event.field, event.value)
    },
    setContent(content) {
      if (this.editedAction.type === this.SEND_EMAIL) {
        this.editedAction.content = `${content} <br> ---- <br> ${this.footer}`

        return
      }

      this.editedAction.content = content
    },
    onChangeWholeDay() {
      // this.editedAction.automationProcedureActionContactThreadTask.startAt = ''
      // this.editedAction.automationProcedureActionContactThreadTask.endAt = ''
    },
    async loadAutomation() {
      const id = this.$route.params.automationCopyId || this.$route.params?.automationId || null

      if (!id || (id === 'new' && !this.$route.params.automationCopyId)) {
        setTimeout(() => {
          this.loading = false
        }, 1000)
        return
      }

      try {
        const resp = await axiosIns.get(`1/automations/procedures/${id}`, { params: { fields_load: this.$route.params.automationCopyId ? 'SINGLE_AUTOMATION_COPY' : this.$fields.SINGLE_AUTOMATION } })

        if (resp.data.data.item) {
          const responseItem = resp.data.data.item
          Object.assign(this.item, responseItem)

          if (this.$route.params.automationCopyId) {
            this.item.name = `${this.item.name} - ${this.$i18n.t('Copy1')}`
          }

          if (responseItem.frequency?.length) {
            const [startCronMonth = '1-12', startCronDayOfMonth = '1-L', startCronDay = '1-7', startCronTime = '12:00'] = responseItem.frequency.split(' ')
            this.cronMonth = startCronMonth
            this.cronDayOfMonth = startCronDayOfMonth
            this.cronDay = startCronDay

            const utcDate = moment.utc(startCronTime, 'HH:mm')
            const localDate = utcDate.local()
            const localTimeString = localDate.format('HH:mm')

            this.cronTime = `${localTimeString}:00`

            this.item.type = 'CRON'
          } else this.item.type = 'TRIGGER'

          this.item.automationProcedureConditions = this.item.automationProcedureConditions.map(condition => {
            const funnelPoints = condition.contactThreadFunnelTypePoints

            const toReturn = {
              ...condition,
              contactThreadFunnelTypePoints: funnelPoints.map(point => ({
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: `${point.contactThreadFunnelType.id}`,
              })),
            }

            if (toReturn.contactAddonFieldType?.id) {
              toReturn.values = toReturn.values.map(e => this.addonContactFieldOptions.find(a => a.value === e))
            }

            return toReturn
          })

          this.item.automationProcedureActions = (this.item?.automationProcedureActions || []).map(action => {
            const point = action.contactThreadFunnelTypePoint
            const thread = action.automationProcedureActionContactThread

            const additionalAutomationProcedureActions = (thread?.additionalAutomationProcedureActions || []).map(additionalAction => {
              // eslint-disable-next-line no-underscore-dangle
              const _additionalAction = {
                ...additionalAction,
                automationProcedureActionContactThreadTask: additionalAction.automationProcedureActionContactThreadTask || JSON.parse(JSON.stringify(this.defaultAction)),
                automationProcedureActionContactThreadNote: additionalAction.automationProcedureActionContactThreadNote || JSON.parse(JSON.stringify(this.defaultAction)),
                automationProcedureActionContact: additionalAction.automationProcedureActionContact || JSON.parse(JSON.stringify(this.defaultAction)),
                automationProcedureActionContactThread: additionalAction.automationProcedureActionContactThread || JSON.parse(JSON.stringify(this.defaultAction)),
              }

              const isDate = _additionalAction?.automationProcedureActionContactThreadTask?.time?.date

              _additionalAction.automationProcedureActionContactThreadTask = {
                ..._additionalAction.automationProcedureActionContactThreadTask,
                time: isDate
                  ? `${_additionalAction.automationProcedureActionContactThreadTask.time.date.split(' ')[1].split(':')[0]}:${_additionalAction.automationProcedureActionContactThreadTask.time.date.split(' ')[1].split(':')[1]}`
                  : _additionalAction.automationProcedureActionContactThreadTask.time,
              }

              delete _additionalAction.automationProcedureActionContactThread
              delete _additionalAction.automationProcedureActionContact

              return _additionalAction
            })

            const isDate = action.automationProcedureActionContactThreadTask?.time?.date

            return {
              ...action,

              automationProcedureActionContactThread: thread ? {
                ...thread,
                additionalAutomationProcedureActions,
                contactThreadFunnelTypePoint: thread.contactThreadFunnelTypePoint ? {
                  ...thread.contactThreadFunnelTypePoint,
                  name: `${thread.contactThreadFunnelTypePoint.contactThreadFunnelType.name} - ${thread.contactThreadFunnelTypePoint.name}`,
                  typeId: `${thread.contactThreadFunnelTypePoint.contactThreadFunnelType.id}`,
                } : '',
              } : this.defaultAction.automationProcedureActionContactThread,
              automationProcedureActionContactThreadTask: action.automationProcedureActionContactThreadTask ? {
                ...action.automationProcedureActionContactThreadTask,
                time: isDate
                  ? `${action.automationProcedureActionContactThreadTask.time?.date?.split(' ')[1].split(':')[0]}:${action.automationProcedureActionContactThreadTask.time?.date?.split(' ')[1].split(':')[1]}`
                  : action.automationProcedureActionContactThreadTask.time,
              } : '',
              contactThreadFunnelTypePoint: point ? {
                ...point,
                name: `${point.contactThreadFunnelType.name} - ${point.name}`,
                typeId: `${point.contactThreadFunnelType.id}`,
              } : '',
            }
          })
        }

        if (resp.data.data.item?.automationProcedureTrigger?.type) {
          const trigger = resp.data.data.item.automationProcedureTrigger

          if (trigger.integrationFacebookWebhook) {
            this.item.automationProcedureTrigger.webhookType = 'FACEBOOK'
          } else if (trigger.contactApplicationIntegration) {
            this.item.automationProcedureTrigger.webhookType = trigger.contactApplicationIntegration.type || '-'
          }

          let point = this.item.automationProcedureTrigger.contactThreadFunnelTypePointTo
          if (point) {
            this.item.automationProcedureTrigger.contactThreadFunnelTypePointTo = {
              ...point,
              name: `${point.contactThreadFunnelType.name} - ${point.name}`,
              typeId: point.contactThreadFunnelType.id,
            }
          }

          point = this.item.automationProcedureTrigger.contactThreadFunnelTypePointFrom
          if (point) {
            this.item.automationProcedureTrigger.contactThreadFunnelTypePointFrom = {
              ...point,
              name: `${point.contactThreadFunnelType.name} - ${point.name}`,
              typeId: point.contactThreadFunnelType.id,
            }
          }

          point = this.item.automationProcedureTrigger.contactThreadChecklistTypePoint
          if (point) {
            this.item.automationProcedureTrigger.contactThreadChecklistTypePoint = {
              ...point,
              name: `${point.contactThreadChecklistType.name} - ${point.name}`,
              typeId: point.contactThreadChecklistType.id,
            }
          }
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    },
    async searchUsers(search, isLoading) {
      this.users = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users?ignore_canLogin=1')
    },
    async loadTemplates() {
      const [smsTemplateResp = [], emailTemplateResp = []] = await Promise.all([
        this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'sms' }),
        this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'email' }),
      ])

      this.smsTemplates = smsTemplateResp.data?.items || []
      this.emailTemplates = emailTemplateResp.data?.items || []
    },
    async loadContactStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactStatuses', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_STATUSES, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) this.contactStatuses = resp.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, name: `${type.name} - ${point.name}` })))
          this.contactThreadFunnelTypes = (resp.data.data.items || [])
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadChecklists() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadChecklistTypes', { params: { fields_load: this.$fields.SETTINGS_CHECKLIST_TYPES, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadChecklistTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadChecklistTypePoints.map(point => ({ ...point, typeId: type.id, name: `${type.name} - ${point.name}` })))
          this.contactThreadChecklistTypes = (resp.data.data.items || [])
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    saveCondition() {
      const index = this.editedCondition?.index ?? -1
      if (index >= 0) {
        this.$set(this.item.automationProcedureConditions, index, { ...JSON.parse(JSON.stringify(this.editedCondition)) })
      } else {
        this.item.automationProcedureConditions.push({ ...JSON.parse(JSON.stringify(this.editedCondition)) })
      }

      this.showEditCondition = false
      this.editedCondition = { ...JSON.parse(JSON.stringify(this.defaultCondition)) }
    },

    async getFiles(files) {
      if (!files.length) return []

      const toUpload = files.filter(f => !f?.token) || []
      const upladed = files.filter(f => f?.token).map(f => f?.token).filter(Boolean) || []

      if (toUpload.length) {
        const payload = { uploadedFiles: toUpload, type: 'building' }
        const r = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

        return [...r.files, ...upladed]
      }

      return [...upladed]
    },

    getParsedActions(automation) {
      /* eslint-disable */
      const _automation = automation?.automationProcedureActions ? automation.automationProcedureActions : automation.additionalAutomationProcedureActions
      return Promise.all(_automation.map(async action => {
          // eslint-disable-next-line no-underscore-dangle
          const _action = {
              ...action,
              files: await this.getFiles(action.files),
              contactStatus: this.getObjectId(action.contactStatus),
              contactThreadFunnelTypePoint: this.getObjectId(action.contactThreadFunnelTypePoint) || null,
              users: action.users.mapKeys('id'),
          }

          if (_action.type === this.CREATE_CONTACT_THREAD_TASK) {
              const task = _action.automationProcedureActionContactThreadTask
              // let startAt = task.startAt?.date || task.startAt || null
              // let endAt = task.endAt?.date || task.endAt || null

              // startAt = task.isWholeDay && !startAt.includes('00:00:00') ? `${startAt} 00:00:00` : startAt
              // endAt = task.isWholeDay ? `${startAt} 23:59:59`.replaceAll('00:00:00', '') : endAt
              // delete task.isWholeDay
              _action.automationProcedureActionContactThreadTask = {
                  ...task,
                  daysAfter: Number(task?.daysAfter) || 0,
                  contactThreadTaskType: this.getObjectId(task.contactThreadTaskType),
                  assignedUser: this.getObjectId(task.assignedUser),
              }


          } else delete _action.automationProcedureActionContactThreadTask

          if (_action.type === this.CREATE_CONTACT_THREAD_NOTE) {
              const note = _action.automationProcedureActionContactThreadNote
              _action.automationProcedureActionContactThreadNote = {
                  ...note,
                  contactThreadNoteType: this.getObjectId(note.contactThreadNoteType),
                  sharedRanks: (note.sharedRanks || []).mapKeys('id'),
              }
          } else delete _action.automationProcedureActionContactThreadNote

          if (_action.type === this.CREATE_CONTACT_WITH_CONTACT_THREAD) {
              const contact = _action.automationProcedureActionContact
              const thread = _action.automationProcedureActionContactThread

              _action.automationProcedureActionContact = {
                  ...contact,
                  status: this.getObjectId(contact.status),
                  userGroups: (contact.userGroups || []).mapKeys('id'),
                  contactTypes: (contact.contactTypes || []).mapKeys('id'),
                  assignedUsers: (contact.assignedUsers || []).mapKeys('id'),
              }

              _action.automationProcedureActionContactThread = {
                  ...thread,
                  contactThreadFunnelTypePoint: this.getObjectId(thread.contactThreadFunnelTypePoint),
                  contactThreadChecklistTypes: (thread.contactThreadChecklistTypes || []).mapKeys('id'),
                  assignedUsers: (contact.assignedUsers || []).mapKeys('id'),
                  additionalAutomationProcedureActions: await this.getParsedActions(thread),
              }
          } else {
              delete _action.automationProcedureActionContact
              delete _action.automationProcedureActionContactThread
          }

          _action.sharedRanks = (action.sharedRanks || []).mapKeys('id')
          _action.automationProcedureActionContactThreadChecklistTypes = (action.automationProcedureActionContactThreadChecklistTypes || []).mapKeys('id'),

            delete _action.template
          delete _action.index
          return _action
      }))
    },
    saveAction() {
      // Nested
      if (typeof this.editedAction?.index === 'object') {
        const { parentIndex = -1, index = -1 } = this.editedAction.index
        if (index >= 0) {
          this.$set(this.item.automationProcedureActions[parentIndex].automationProcedureActionContactThread.additionalAutomationProcedureActions, index, { ...this.editedAction })
        } else {
          this.item.automationProcedureActions[parentIndex].automationProcedureActionContactThread.additionalAutomationProcedureActions.push({ ...this.editedAction })
        }
      } else {
        const index = this.editedAction?.index ?? -1
        if (index >= 0) {
          this.$set(this.item.automationProcedureActions, index, { ...this.editedAction })
        } else {
          this.item.automationProcedureActions.push({ ...this.editedAction })
        }
      }

      this.showEditAction = false
      this.editedAction = { ...JSON.parse(JSON.stringify(this.defaultAction)) }
    },

    async saveAutomation() {
        const isInvalid = await this.$refs.validator.validate()
        if (!isInvalid) return

      try {
        this.loading = true
        const automation = { ...this.item }

        delete automation.automationProcedureTrigger.webhookType
        automation.automationProcedureTrigger.executionDelayDays = Number(automation.automationProcedureTrigger.executionDelayDays || 0)
        automation.automationProcedureTrigger.contactStatusFrom = this.getObjectId(automation.automationProcedureTrigger.contactStatusFrom) || null
        automation.automationProcedureTrigger.contactStatusTo = this.getObjectId(automation.automationProcedureTrigger.contactStatusTo) || null
        automation.automationProcedureTrigger.contactThreadFunnelTypePointTo = this.getObjectId(automation.automationProcedureTrigger.contactThreadFunnelTypePointTo) || null
        automation.automationProcedureTrigger.contactThreadFunnelTypeTo = this.getObjectId(automation.automationProcedureTrigger.contactThreadFunnelTypeTo) || null
        automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom = this.getObjectId(automation.automationProcedureTrigger.contactThreadFunnelTypePointFrom) || null
        automation.automationProcedureTrigger.contactThreadFunnelTypeFrom = this.getObjectId(automation.automationProcedureTrigger.contactThreadFunnelTypeFrom) || null
        automation.automationProcedureTrigger.contactThreadChecklistTypePoint = this.getObjectId(automation.automationProcedureTrigger.contactThreadChecklistTypePoint) || null
        automation.automationProcedureTrigger.contactThreadOrderStatusTo = this.getObjectId(automation.automationProcedureTrigger.contactThreadOrderStatusTo) || null
        automation.automationProcedureTrigger.contactThreadOrderStatusFrom = this.getObjectId(automation.automationProcedureTrigger.contactThreadOrderStatusFrom) || null
        automation.automationProcedureTrigger.contactThreadOfferStatusTo = this.getObjectId(automation.automationProcedureTrigger.contactThreadOfferStatusTo) || null
        automation.automationProcedureTrigger.contactThreadOfferStatusFrom = this.getObjectId(automation.automationProcedureTrigger.contactThreadOfferStatusFrom) || null
        automation.automationProcedureTrigger.contactApplicationIntegration = this.getObjectId(automation.automationProcedureTrigger.contactApplicationIntegration) || null
        automation.automationProcedureTrigger.integrationFacebookWebhook = this.getObjectId(automation.automationProcedureTrigger.integrationFacebookWebhook) || null
        automation.automationProcedureTrigger.contactThreadTaskStatusTo = this.getObjectId(automation.automationProcedureTrigger.contactThreadTaskStatusTo) || null
        automation.automationProcedureTrigger.contactThreadTaskTypes = (automation.automationProcedureTrigger?.contactThreadTaskTypes || []).mapKeys('id') || []
        automation.automationProcedureTrigger.repeatType = (automation.automationProcedureTrigger?.repeatType)|| 'ONCE'
        automation.automationProcedureActions = await this.getParsedActions(automation)

        automation.automationProcedureConditions = automation.automationProcedureConditions.map(condition => {
                const toReturn =  {
                    ...condition,
                    contactAddonFieldType: this.getObjectId(condition.contactAddonFieldType) || null,
                    contactStatuses: condition.contactStatuses.mapKeys('id'),
                    contactThreadFunnelTypePoints: condition.contactThreadFunnelTypePoints.mapKeys('id'),
                    users: condition.users.mapKeys('id'),
                }

                if (toReturn.contactAddonFieldType) {
                    toReturn.values = toReturn.values.mapKeys('value')
                }

                return toReturn
        })

        if (automation.type === 'CRON') {
          const time = this.cronTime.slice(0, -3)
          const timeString = time.includes(':') ? time : `${time}:00`

          let localDate = moment(timeString, "HH:mm");
          let utcDate = localDate.utc();
          let utcTimeString = utcDate.format("HH:mm");

          automation.frequency = `${this.cronMonth} ${this.cronDayOfMonth} ${this.cronDay} ${utcTimeString}`
        } else {
          automation.frequency = null
        }

        const res = await axiosIns({
          url: '1/automations/procedures',
          method: automation.id ? 'PATCH' : 'POST',
          data: automation,
        })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        if (!automation.id) {
          await this.$router.replace({ name: 'automations', params: { automationId: res.data.data.items[0].id } })
        }
        await this.loadAutomation()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
  setup() {
    const selectedCondition = ref(null)
    const selectedAction = ref(null)

    const types = reactive([{ label: 'Contact', value: 'CONTACT' }, { label: 'Thread', value: 'THREAD' }])
    const time = reactive({
      dayOfMonth: [
        { label: 'EveryDayOfMonth', value: '1-L' },
        { label: 'LastDayOfMonth', value: 'L-L' },
        ...(new Array(31)).fill(null).map((e, i) => ({ value: `${i + 1}-${i + 1}`, label: i + 1 })),
      ],
        addonContactFieldOptions: [
            { value: `now`, label: 'IsToday' },
            ...(new Array(10)).fill(null).map((e, i) => ({ value: `+${i + 1} day`, label: i + 1, suffix: 'AutomationDaysBefore' })),
            ...(new Array(10)).fill(null).map((e, i) => ({ value: `-${i + 1} day`, label: i + 1, suffix: 'AutomationDaysAfter' })),
        ],
      day: [
        { label: 'EveryDay', value: '1-7' },
        { label: 'EveryWorkDay', value: '1-5' },
        { label: 'Weekend', value: '6-7' },
        { label: 'Monday', value: '1-1' },
        { label: 'Tuesday', value: '2-2' },
        { label: 'Wednesday', value: '3-3' },
        { label: 'Thursday', value: '4-4' },
        { label: 'Friday', value: '5-5' },
        { label: 'Saturday', value: '6-6' },
        { label: 'Sunday', value: '7-7' },
      ],
      month: [
        { label: 'EveryMonth', value: '1-12' },
        { label: 'January', value: '1-1' },
        { label: 'February', value: '2-2' },
        { label: 'March', value: '3-3' },
        { label: 'April', value: '4-4' },
        { label: 'May', value: '5-5' },
        { label: 'June', value: '6-6' },
        { label: 'July', value: '7-7' },
        { label: 'August', value: '8-8' },
        { label: 'September', value: '9-9' },
        { label: 'October', value: '10-10' },
        { label: 'November', value: '11-11' },
        { label: 'December', value: '12-12' },
      ],
    })

    return {
      selectedCondition,
      selectedAction,
      // Data
      types,
      time,

      automationsTypes,
    }
  },
}
</script>

<style lang="scss">
.message-editor {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }

  .ql-container {
    resize: vertical;
    overflow: auto;
  }
}
</style>
