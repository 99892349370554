<template>
  <b-card>
    <b-tabs pills>
      <b-tab
        v-for="(tab, index) in tabs"
        :key="`setting_tab_${index}`"
        @click="tw"
      >
        <template #title>
          <span>{{ $t(`${tab.title}`) }}</span>

          <div
            v-if="!tab.isSaved"
            v-b-tooltip.hover.v-warning
            :title="$t('UnsavedChanges')"
          >
            <feather-icon
              class="ml-50"
              icon="AlertTriangleIcon"
            />
          </div>
        </template>

        <component
          :is="tab.component"
          :ref="tab.ref"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { BTab, BTabs, VBTooltip } from 'bootstrap-vue'
import ContactThreadGeneralSettings from '@/views/core/admin/adminSettingsTabs/ContactThreadGeneralSettings.vue'
import FunnelStatuses from '@/views/core/admin/adminSettingsTabs/FunnelStatuses.vue'
import Checklist from '@/views/core/admin/adminSettingsTabs/Checklist.vue'
import Notes from '@/views/core/admin/adminSettingsTabs/Notes.vue'
import CloseReasons from '@/views/core/admin/adminSettingsTabs/CloseReasons.vue'
import Tasks from '@/views/core/admin/adminSettingsTabs/Tasks.vue'
import OfferPolls from '@/views/core/admin/adminSettingsTabs/OfferPolls.vue'

export default {
  name: 'AdminSettings',
  components: {
    OfferPolls,
    Tasks,
    CloseReasons,
    Notes,
    Checklist,
    FunnelStatuses,
    BTabs,
    BTab,
    ContactThreadGeneralSettings,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters({
      notes: 'getNotes',
      checklist: 'getChecklist',
      funnelStatuses: 'typeSettings/getFunnelStatuses',
    }),
  },
  data: () => ({
    tabs: [
      {
        title: 'General', ref: 'setting_general', component: 'contact-thread-general-settings', isSaved: true,
      },
      {
        title: 'FunnelStatuses', ref: 'setting_funnel', component: 'funnel-statuses', isSaved: true,
      },
      {
        title: 'CloseReasons', ref: 'close_reasons', component: 'close-reasons', isSaved: true,
      },
      {
        title: 'Checklist', ref: 'setting_checklist', component: 'checklist', isSaved: true,
      },
      {
        title: 'Notes', ref: 'setting_notes', component: 'notes', isSaved: true,
      },
      {
        title: 'Tasks', ref: 'setting_tasks', component: 'tasks', isSaved: true,
      },
    ],
  }),
  methods: {
    tw() {
      this.tabs.forEach((tab, index) => {
        this.tabs[index].isSaved = this.$refs[tab.ref][0].areChangesSaved
      })
    },
  },
}
</script>

<style scoped>

</style>
