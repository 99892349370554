<template>
  <div>
    <div
      v-if="!loading"
      class="pt-1"
    >
      <div>
        <!--   Add new Funnel   -->
        <b-form class="pb-1 w-100 d-flex flex-column flex-sm-row mb-1">
          <b-form-input
            v-model="newFunnelName"
            style="max-width: 200px; min-width: 200px"
            size="sm"
            required
            :placeholder="$t('FunnelName')"
          />

          <b-button
            style="min-width: 125px; max-width: 150px"
            class="ml-sm-1 mt-50 mt-sm-0"
            variant="success"
            size="sm"
            :disabled="newFunnelName === ''"
            @click="addFunnel"
          >
            {{ $t('Add') }}
          </b-button>
        </b-form>
        <!--   Add new Funnel   -->

        <!-- Funnels list -->
        <app-collapse
          accordion
          type="margin"
        >
          <draggable
            v-model="funnels"
            tag="div"
            class="cursor-pointer"
          >
            <app-collapse-item
              v-for="(funnel, index) in funnels"
              :key="funnel.id + '_' + index"
              :is-status="true"
              show-actions
              :show-edit="false"
              @copy="copyFunnel(funnel)"
              @delete="removeFunnel(index, funnel.id)"
            >

              <template #title>
                <feather-icon
                  icon="MoveIcon"
                  class="text-primary mr-25"
                  size="18"
                />
                {{ funnel.name }}
              </template>
              <!-- funnel settings -->
              <b-form-group>
                <div
                  class="d-flex flex-row flex-wrap align-items-center"
                  style="column-gap: .75rem; row-gap: .34rem;"
                >
                  <label
                    class="h6 mb-0"
                    style="width: max-content"
                  >
                    {{ $t('FunnelName') }}
                  </label>

                  <b-form-input
                    v-model="funnel.name"
                    size="sm"
                    type="text"
                    style="max-width: 200px"
                    @blur="onChangeFunnelName"
                  />
                </div>
              </b-form-group>
              <!-- funnel settings -->

              <!-- Row Loop -->
              <draggable
                v-model="funnel.contactThreadFunnelTypePoints"
                tag="div"
                class="cursor-pointer"
              >
                <b-row
                  v-for="(status, statusIndex) in funnel.contactThreadFunnelTypePoints"
                  :id="status.id"
                  :key="status.id"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col
                    sm="12"
                    class="d-flex align-items-center"
                  >
                    <span
                      class="h5 d-flex align-items-center"
                      style="column-gap: .5rem"
                    >
                      <feather-icon
                        icon="MoveIcon"
                        class="text-primary mr-25"
                        size="18"
                      />

                      {{ status.name }}
                      <sw-icon v-if="status.description"
                               v-b-tooltip
                               class="text-primary ml-25"
                               icon="AlertCircleIcon"
                               :title="status.description"
                      />
                    </span>
                  </b-col>

                  <!-- Cost -->
<!--                  <b-col md="2">-->
<!--                    <label>{{ $t('ProgressPercent') }}</label>-->
<!--                    <div>-->
<!--                      <b-badge-->
<!--                        variant="light-primary"-->
<!--                        size="lg"-->
<!--                      >-->
<!--                        {{ status.progressPercent }}-->
<!--                      </b-badge>-->
<!--                    </div>-->
<!--                  </b-col>-->

                  <!-- Quantity -->
                  <b-col md="2">
                    <label>{{ $t('AmountOfDaysInStatus') }}</label>
                    <div>
                      <b-badge
                        variant="light-primary"
                        size="lg"
                      >
                        {{ status.maxDurationStay }}
                      </b-badge>
                    </div>
                  </b-col>

                  <!-- Profession -->
                  <b-col
                    lg="2"
                    md="1"
                  >
                    <b-form-group
                      :label="$t('Color')"
                      label-for="color"
                    >
                      <b-form-input
                        id="color"
                        v-model="status.color"
                        disabled
                        size="sm"
                        type="color"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="4"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      size="sm"
                      variant="outline-warning"
                      class=" mr-1"
                      @click="editField(index, status, statusIndex, funnel.id)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('Edit') }}</span>
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      size="sm"
                      @click="removeStatusItem(index, statusIndex, status.id)"
                    >
                      <span>
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ removedFields.includes(status.id ? status.id.toString() : false) ? $t('Cancel') : $t('Delete') }}</span>
                      </span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </draggable>
              <b-button
                size="sm"
                variant="primary"
                @click="addField(index, funnel.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('Add') }}</span>
              </b-button>
            </app-collapse-item>
          </draggable>
        </app-collapse>
        <!-- Funnels list -->
      </div>
      <b-modal
        v-model="fieldChanges.isFieldEdited"
        hide-footer
        no-close-on-backdrop
      >
        <!-- Edited name  -->
        <b-form-group
          :label="$t('Name')"
          label-for="status_name"
        >
          <b-form-input
            id="status_name"
            v-model="fieldChanges.editedField.name"
            type="text"
          />
        </b-form-group>
        <!-- Edited days -->
        <b-form-group
          :label="$t('AmountOfDaysInStatus')"
          label-for="maxDurationStay"
        >
          <b-form-input
            id="maxDurationStay"
            v-model="fieldChanges.editedField.maxDurationStay"
            type="number"
          />
        </b-form-group>
<!--        <b-form-group-->
<!--          :label="$t('ProgressPercent')"-->
<!--          label-for="progressPercent"-->
<!--        >-->
<!--          <b-form-input-->
<!--            id="progressPercent"-->
<!--            v-model="fieldChanges.editedField.progressPercent"-->
<!--            type="number"-->
<!--          />-->
<!--        </b-form-group>-->
        <b-form-group
          :label="$t('Tooltip')"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="fieldChanges.editedField.description"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Color')"
          label-for="color_m"
        >
          <b-form-input
            id="color_m"
            v-model="fieldChanges.editedField.color"
            size="sm"
            type="color"
          />
        </b-form-group>
        <b-button
          variant="success"
          class="mt-1"
          size="sm"
          @click="saveField"
        >
          {{ $t('Confirm') }}
        </b-button>
      </b-modal>
      <!-- edited modal -->
      <b-button
        variant="primary"
        class="mr-1 mt-1"
        size="sm"
        @click="onSaveForm"
      >
        <span>{{ $t('Save') }}</span>
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import axiosIns from '@/libs/axios'
import draggable from 'vuedraggable'

export default {
  name: 'FunnelStatuses',
  components: {
    BSpinner,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    removedFields: [],
    newFunnelName: '',
    funnels: [],
    areChangesSaved: true,
    statuses: '',
    statusList: {
      COMPLETED: 'COMPLETED',
      REJECTED: 'REJECTED',
    },
    fieldChanges: {
      isFieldEdited: false,
      funnelIndex: null,
      funnelId: null,
      editedFieldIndex: null,
      editedField: {
        id: null, name: '', description: '', color: '', maxDurationStay: 0, progressPercent: 0, contactThreadFunnelTypePoints: null,
      },
    },
    mFunnelName: '',
  }),
  mounted() {
    this.loading = true
    this.statuses = { ...this.$store.getters['typeSettings/getFunnelStatuses'] }
    axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
      .then(res => {
        this.funnels = res.data.data.items
      })
      .finally(() => {
        this.loading = false
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    aFunnelStatus(funnel, funnelIndex, status, statusIndex, statut) {
      this.$swal({
        title: `${this.$t('Warning')} ${status.name}`,
        text: this.$t('funnel.changeStatusWarning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.isConfirmed) {
          if (this.funnels[funnelIndex].contactThreadFunnelTypePoints.find(el => el.type === statut)) this.funnels[funnelIndex].contactThreadFunnelTypePoints.find(el => el.type === statut).type = null

          this.funnels[funnelIndex].contactThreadFunnelTypePoints[statusIndex].type = statut
        }
      })
    },
    onChangeFunnelName() {
      this.areChangesSaved = false
    },
    addFunnel() {
      const funnelName = this.newFunnelName
      axiosIns.post('1/settings/contactThreadFunnelTypes', { name: funnelName })
        .then(res => {
          this.funnels.push({
            name: funnelName, id: res.data.data.items[0].id, contactThreadFunnelTypePoints: [],
          })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.newFunnelName = ''
        })
    },
    async saveFunnel(name, index = 1) {
      const funnelName = name

      return axiosIns.post('1/settings/contactThreadFunnelTypes', { name: funnelName, position: index + 1 })
        .then(res => res.data.data.items[0].id)
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    removeFunnel(funnelIndex, funnelId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (funnelId) {
            axiosIns.delete('1/settings/contactThreadFunnelTypes', { data: [funnelId] })
              .then(() => {
                this.funnels.splice(funnelIndex, 1)
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          } else { this.funnels.splice(funnelIndex, 1) }
        })
    },
    copyFunnel(funnel) {
      const newFunnel = JSON.parse(JSON.stringify(funnel))

      newFunnel.contactThreadFunnelTypePoints = newFunnel.contactThreadFunnelTypePoints.map(t => {
        // eslint-disable-next-line
        delete t.id
        // eslint-disable-next-line
        delete t.contactThreadFunnelType

        return t
      })

      delete newFunnel.id

      newFunnel.name += ' – copy'

      this.funnels.push(newFunnel)
    },
    editField(funnelIndex, field, index, funnelId) {
      this.fieldChanges.isFieldEdited = true
      this.fieldChanges.editedField = { ...field, isNew: !field.id }
      this.fieldChanges.editedFieldIndex = index
      this.fieldChanges.funnelIndex = funnelIndex
      this.fieldChanges.funnelId = funnelId
      this.areChangesSaved = false
    },
    removeStatusItem(funnelIndex, statusIndex, fieldId) {
      if (fieldId) {
        if (this.removedFields.includes(fieldId.toString())) this.removedFields.splice(this.removedFields.indexOf(fieldId.toString()), 1)
        else this.removedFields.push(fieldId.toString())
      } else this.funnels[funnelIndex].contactThreadFunnelTypePoints.splice(statusIndex, 1)
      this.areChangesSaved = false
    },
    addField(funnelIndex, funnelId) {
      this.resetField()
      this.fieldChanges.isFieldEdited = true
      this.fieldChanges.funnelIndex = funnelIndex
      this.fieldChanges.funnelId = funnelId
    },
    saveField() {
      const editedField = this.fieldChanges

      const {
        id, name, color, type, maxDurationStay, contactThreadFunnelType, progressPercent, isNew, description,
      } = editedField.editedField

      const item = {
        // eslint-disable-next-line radix
        id, name, color, type, maxDurationStay: parseInt(maxDurationStay), contactThreadFunnelType, progressPercent: parseInt(progressPercent || 0), description,
      }

      item.contactThreadFunnelType = editedField.funnelId ? editedField.funnelId.toString() : ''
      item.position = 0

      if (!item.id) delete item.id
      else item.id = item.id.toString()

      if (item.id || isNew) {
        this.funnels[editedField.funnelIndex].contactThreadFunnelTypePoints[editedField.editedFieldIndex] = item
      } else {
        this.funnels[editedField.funnelIndex].contactThreadFunnelTypePoints.push(item)
      }

      this.resetField()
      this.areChangesSaved = false
    },
    resetField() {
      this.fieldChanges.isFieldEdited = false
      this.fieldChanges.editedField = {
        id: null, name: '', color: '', maxDurationStay: 0, progressPercent: 0, contactThreadFunnelTypePoints: null, description: '',
      }
      this.fieldChanges.editedFieldIndex = null
      this.fieldChanges.funnelId = null
      this.fieldChanges.funnelIndex = null
    },
    async onSaveForm() {
      this.loading = true
      const promises = this.funnels.map(async (funnel, index) => ({
        id: funnel.id || await this.saveFunnel(funnel.name),
        name: funnel.name,
        position: index + 1,
      }))

      const funnels = await Promise.all(promises)

      let points = []

      this.funnels.forEach((funnel, i) => funnel.contactThreadFunnelTypePoints.forEach((point, index) => {
        const {
          id,
          name,
          color,
          type,
          maxDurationStay,
          contactThreadFunnelType,
          // progressPercent,
          description,
        } = point

        const preparedPoint = {
          name,
          color,
          type,
          maxDurationStay,
          contactThreadFunnelType,
          progressPercent: 0,
          description,
        }

        if (id) {
          preparedPoint.id = id.toString()
        }

        // eslint-disable-next-line no-const-assign,no-param-reassign
        preparedPoint.contactThreadFunnelType = funnels[i].id

        preparedPoint.position = index

        points.push(preparedPoint)
      }))

      points = points.filter(point => !this.removedFields.includes(point.id))

      axiosIns.put('1/settings/contactThreadFunnelTypes', funnels)
        .then(() => {
          if (this.removedFields.length) {
            axiosIns.delete('1/settings/contactThreadFunnelTypePoints', { data: this.removedFields })
              .then(() => {
                this.save(points)
              })
              .catch(err => {
                this.removedFields = []
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          } else this.save(points)
        })
        .catch(err => {
          this.removedFields = []
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    save(points) {
      if (points.length) {
        axiosIns.put('1/settings/contactThreadFunnelTypePoints', points)
          .then(() => {
            axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
              .then(items => {
                this.funnels = items.data.data.items
                this.showToast('success', this.$i18n.t('success.contactUpdated'))
                this.areChangesSaved = true
              })
              .catch(err => {
                this.removedFields = []
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
              .finally(() => {
                this.loading = false
                this.removedFields = []
              })
          })
          .catch(err => {
            this.removedFields = []
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else {
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.loading = false
      }
    },
  },

}
</script>

<style scoped>

</style>
