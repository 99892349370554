<template>
  <b-row>
    <b-col
      md="6"
      sm="12"
    >
      <div class="form-label-group">
        <b-form-input
          id="template-name"
          v-model="template.name"
          :placeholder="$t('Title')"
        />
        <label for="template-name">{{ $t('Title') }}</label>
      </div>
      <div
        class="form-label-group"
      >
        <quill-editor
          v-model="template.content"
          :options="editorOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <!-- Add a bold button -->
            <span class="ql-formats">
              <button class="ql-clean" />
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-link" />
            </span>
            <span class="ql-formats">
              <button
                class="ql-script"
                value="sub"
              />
              <button
                class="ql-script"
                value="super"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              />
              <button
                class="ql-list"
                value="bullet"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-indent"
                value="-1"
              />
              <button
                class="ql-indent"
                value="+1"
              />
            </span>
            <span class="ql-formats">
              <select class="ql-align" />
              <select class="ql-color" />
            </span>
            <span class="ql-formats">
              <select class="ql-size" />
              <select class="ql-header" />
              <select class="ql-font" />
            </span>

            <!-- You can also add your own -->
            <span class="ql-formats">
              <b-button
                id="custom-button"
                variant="success"
                class="w-auto"
                @click="$emit('open-modal')"
              >
                <span class="text-white">
                  <feather-icon
                    icon="CodeIcon"
                    class="mr-25"
                  />
                </span>

                <span class="text-white">
                  {{ $t('ShortCodes') }}
                </span>
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="preview_swiper mr-50 mb-25 ml-50"
                @click="templatePreview = !templatePreview; previewContent = template.content"
              >
                <feather-icon :icon="!templatePreview ? 'EyeIcon' : 'EyeOffIcon'" />
              </b-button>
            </span>
          </div>
        </quill-editor>
      </div>
      <b-button
        size="sm"
        class="mt-50"
        variant="success"
        :disabled="!template.content || !template.name"
        @click="saveTemplate"
      >
        {{ editedTemplateId ? $t('Update') : $t('Save') }}
      </b-button>
      <b-button
        v-if="editedTemplateId"
        size="sm"
        class="ml-50 mt-50"
        @click="editField(null)"
      >
        {{ $t('Cancel') }}
      </b-button>
    </b-col>
    <b-col
      md="6"
      sm="12"
      class="mt-1 mt-md-0"
    >
      <b-table
        ref="selectableTable"
        :items="templates"
        class="table-wrap-words"
        :fields="fields"
        striped
        responsive
        selectable
        select-mode="multi"
        :busy="loading"
        show-empty
        @row-selected="onRowSelected"
      >
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>
        <template #empty>
          <div
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
        </template>
        <template #head(selected)>
          <b-form-checkbox
            variant="primary"
            :checked="templates.length === selectedTemplates.length"
            @change="changeAllSelection"
          />
        </template>
        <template #cell(selected)="rowSelected">
          <b-form-checkbox
            v-model="selectedTemplates"
            variant="primary"
            :value="rowSelected.item"
            @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"
          />
        </template>
        <template #cell(content)="row">
          <span :inner-html.prop="row.value | truncate(20, '...')" />
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            class="mr-50"
            @click="previewContent = row.item.content; templatePreview = !templatePreview"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            size="sm"
            class="mr-50"
            @click="editField(row.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <vue-select
            v-model="pagination.limit"
            style="min-width: 75px"
            class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"
            :options="[5, 10, 15, 25]"
            :clearable="false"
            @input="reloadTemplates(pagination.currentPage, $event)"
          />
        </div>
        <div>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rows"
            :per-page="pagination.limit"
            align="center"
            @change="reloadTemplates($event, pagination.limit)"
          />
        </div>
      </div>
    </b-col>
    <transition name="fade">
      <div
        v-if="selectedTemplates.length"
        style="position: fixed; right: 30px; bottom: 20px"
        class="shadow"
      >
        <b-button
          variant="danger"
          class="d-flex align-items-center"
          size="sm"
          @click="removeTemplates"
        >
          <feather-icon icon="TrashIcon" />
          <span class="pr-1 pl-25">{{ $t('Delete') }}</span>
          <b-badge variant="light text-danger">
            {{ selectedTemplates.length }}
          </b-badge>
        </b-button>
      </div>
    </transition>

    <b-modal
      v-model="templatePreview"
      hide-footer
      :title="$t('Preview')"
      size="lg"
    >
      <div style="overflow: auto">
        <email-preview-modal :template="previewContent" />
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import VueSelect from 'vue-select'
import {
  BTable, BPagination,
} from 'bootstrap-vue'
import { GET_TEMPLATES, SAVE_TEMPLATE, DELETE_TEMPLATE } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { defaultEmailTemplate } from '@/@data/emailTemplates'
import EmailPreviewModal from '@/views/modals/EmailPreviewModal.vue'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

export default {
  name: 'Email',
  components: {
    EmailPreviewModal,
    BTable,
    BPagination,
    VueSelect,
    quillEditor,
  },
  data: () => ({
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      limit: 5,
      currentPage: 1,
    },
    editorOption: {
      placeholder: '',
      modules: {
        toolbar: '#toolbar',
      },
    },
    loading: false,
    selectedTemplates: [],
    fields: [
      {
        key: 'selected',
      },
      {
        key: 'name',
        label: 'Title',
      },
      {
        key: 'content',
        label: 'Template',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
    editedTemplateId: null,
    template: {
      content: defaultEmailTemplate,
      name: '',
    },
    previewContent: '',
    templatePreview: false,
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
      templates: 'templates/getEmailTemplates',
      rows: 'templates/getEmailTemplatesLength',
      system: 'system/getSettings',
    }),
  },
  mounted() {
    this.reloadTemplates()
  },
  methods: {
    reloadTemplates(page = this.pagination.currentPage, limit = this.pagination.limit) {
      this.loading = true

      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'email', page: this.rows < limit ? 1 : page, limit })
        .then(() => {
          setTimeout(() => { this.loading = false }, 500)
        })
        .catch(() => {
          this.loading = false
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    editField(item) {
      if (item) {
        this.editedTemplateId = item.id
        this.template = {
          content: item.content.replaceAll('\n', '<br />'),
          name: item.name,
        }
      } else {
        this.editedTemplateId = null
        this.template = {
          content: '',
          name: '',
        }
      }
    },
    changeSelection(e, index) {
      if (!e) this.$refs.selectableTable.selectRow(index)
      else this.$refs.selectableTable.unselectRow(index)
    },
    changeAllSelection() {
      if (this.templates.length === this.selectedTemplates.length) this.$refs.selectableTable.clearSelected()
      else this.$refs.selectableTable.selectAllRows()
    },
    onRowSelected(items) {
      this.selectedTemplates = items
    },
    insertShortcode(shortcode) {
      const { content } = this.template
      const textarea = this.$refs.content.$refs.input
      const sentenceLength = content.length
      let position = textarea.selectionStart

      if (position === undefined) {
        position = sentenceLength - 1
      }

      const before = content.substr(0, position)
      const after = content.substr(position, sentenceLength)

      this.template.content = before + shortcode.shortcode + after

      this.$nextTick().then(() => {
        textarea.focus()
      })
    },
    saveTemplate() {
      const { template } = this
      const content = template.content.replaceAll('\n', '<br>')
      template.content = content
      if (this.editedTemplateId) template.id = this.editedTemplateId
      this.$store.dispatch(`templates/${SAVE_TEMPLATE}`, { type: 'email', template })
        .then(() => {
          this.editedTemplateId = null
          this.template = {
            content: '',
            name: '',
          }
          this.reloadTemplates()
          this.showToast('success', this.editedTemplateId ? this.$i18n.t('templates.TemplateUpdated') : this.$i18n.t('templates.TemplateSaved'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    removeTemplates() {
      const templatesToRemove = this.selectedTemplates.map(template => template.id.toString())
      this.$store.dispatch(`templates/${DELETE_TEMPLATE}`, { templatesToRemove, type: 'email' })
        .then(() => {
          this.reloadTemplates()
          this.showToast('success', this.$i18n.t('templates.TemplateDeleted'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
  },
}
</script>

<style scoped>

</style>
