<template>
  <div>
    <!--    <b-card v-if="allowForDev">-->
    <!--      <b-alert show variant="primary">-->
    <!--        <div class="alert-body">{{ $t('AiOnlyMasterPlan') }}</div>-->
    <!--      </b-alert>-->
    <!--      <b-form-checkbox v-model="globalSettings.clientChatAutoEnableAi"-->
    <!--                       variant="primary"-->
    <!--                       class="mb-50"-->
    <!--                       @change="changeAiAutoEnabledSettings"-->
    <!--      >-->
    <!--        {{ $t('TurnOffDefaultAiAnswering') }}-->
    <!--      </b-form-checkbox>-->
    <!--      <b-form-radio-group v-if="globalSettings && globalSettings.clientChatAutoEnableAi"-->
    <!--                          v-model="globalSettings.clientChatDefaultAiType"-->
    <!--                          @change="changeAiSettings"-->
    <!--      >-->
    <!--        <b-form-radio v-for="(aiOption, index) in aiOptions"-->
    <!--                      :key="`ai_option_${index}`"-->
    <!--                      :disabled="!(isKronen && aiOption === 'QUICK_CHAT')"-->
    <!--                      :value="aiOption"-->
    <!--        >-->
    <!--          {{ aiOption ? $t(aiOption) : $t('TurnOffDefaultAiAnswering') }}-->
    <!--        </b-form-radio>-->
    <!--      </b-form-radio-group>-->

    <!--      <b-overlay :show="loading"-->
    <!--                 variant="primary"-->
    <!--      />-->
    <!--    </b-card>-->

    <b-button
      size="sm"
      class="mb-50"
      variant="primary"
      @click="$router.push({ name: 'edit-ai-connection', params: { id: 'new' } })"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>
    <!-- eslint-disable -->
    <b-card>
      <sw-table
          table-id="2aa2fe3d81d04ad3ad8816a2cb2d9357"
          show-columns
          show-search
          :fields.async="connectionFields"
          :pagination="pagination"
          @change-pagination="Object.assign(pagination, $event)"
          @change-search-query="searchQuery = $event"
          @reload-content="loadData"
          @set-fields="connectionFields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
              :items="items"
              class="offer-table"
              :fields="fields"
              striped
              responsive
              :busy="loading"
              show-empty
              :style="{ minHeight: tableSize(items, 200) }"
              :sort-by.sync="sorter.sortBy"
              :sort-desc.sync="sorter.sortDesc"
              :no-local-sorting="true"
              @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <template #cell(files)="{ value, item }">
              <documents-list
                  :attach="'body'"
                  v-if="Array.isArray(value) && value.length"
                  :documents="value"
              />
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(name)="{ value, item }">
              <router-link :to="{ name: 'edit-ai-connection', params: { id: item.id } }">
                <span v-if="value">{{ value }}</span>
                <feather-icon icon="MinusIcon" v-else />
              </router-link>
            </template>

            <template #cell(connectedData)="{ value, item, index }">
              <div v-if="Array.isArray(value) && value">
                <b-badge variant="primary" :id="`connectedData_${index}_${item.id}`" class="cursor-pointer">{{ $t('Modules') }}: {{ value.length }}</b-badge>
                <b-popover
                    :target="`connectedData_${index}_${item.id}`"
                    triggers="hover"
                >
                  <b-list-group flush>
                    <b-list-group-item v-for="(listItem, listIndex) of value" :key="`connectedData_${index}_${item.id}_${listIndex}`">
                      {{ listItem.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </div>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(postedData)="{ value, item, index }">
              <div v-if="Array.isArray(value) && value">
                <b-badge variant="primary" :id="`postedData_${index}_${item.id}`" class="cursor-pointer">{{ $t('Files') }}: {{ value.length }}</b-badge>
                <b-popover
                    :target="`postedData_${index}_${item.id}`"
                    triggers="hover"
                >
                  <b-list-group flush>
                    <b-list-group-item v-for="(listItem, listIndex) of value" :key="`postedData_${index}_${item.id}_${listIndex}`">
                      {{ listItem.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </div>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(dataStatus)="{ value, item }">
              <b-badge v-if="value" :variant="getStatusVariant(value)">{{ $t(value.toLowerCase().ucFirst()) }}</b-badge>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(status)="{ value, item }">
              <b-badge v-if="value" :variant="getStatusVariant(value)">{{ $t(value.toLowerCase().ucFirst()) }}</b-badge>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

              <template #cell(model)="{ value, item }">
                <span v-if="value">{{ value }}</span>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(used)="{ value, item, index }">
              <b-badge variant="light-primary">
                {{ item.salesAiChannels.length }} {{ $tc('Channel', item.salesAiChannels.length) }}
              </b-badge>
<!--              <div v-if="Array.isArray(value) && value">-->
<!--                <b-badge variant="primary" :id="`used_${index}_${item.id}`" class="cursor-pointer">{{ $t('Files') }}: {{ value.length }}</b-badge>-->
<!--                <b-popover-->
<!--                    :target="`used_${index}_${item.id}`"-->
<!--                    triggers="hover"-->
<!--                >-->
<!--                  <b-list-group flush>-->
<!--                    <b-list-group-item v-for="(listItem, listIndex) of value" :key="`used_${index}_${item.id}_${listIndex}`">-->
<!--                      {{ listItem.name }}-->
<!--                    </b-list-group-item>-->
<!--                  </b-list-group>-->
<!--                </b-popover>-->
<!--              </div>-->
<!--              <feather-icon-->
<!--                  v-else-->
<!--                  icon="MinusIcon"-->
<!--              />-->
            </template>
            <template #cell(wear)="{ value, item }">
              {{ value }} {{ $t('Tokens').toLowerCase() }}
            </template>
            <template #cell(createdBy)="{ value, item }">
              <avatar :user="value" />
            </template>
            <template #cell(effects)="{ value, item }">
              <b-badge v-b-tooltip :title="$t('EffectsPositive')" variant="light-success" class="mr-25">{{ item.effectPositive }}</b-badge>
              <b-badge v-b-tooltip :title="$t('EffectsNegative')" variant="light-danger">{{ item.effectNegative }}</b-badge>
            </template>

            <!--    Table Action    -->
            <template #cell(action)="{ item, index }">
              <b-dropdown
                  variant="link"
                  class="dropdown-icon-wrapper text-decoration-none"
                  no-caret
              >
                <template
                    #button-content
                    class="sr-only"
                >
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle"
                  />
                </template>

                <!--    Action: Duplicate    -->
                <b-dropdown-item @click="$router.push({ name: 'edit-ai-connection', params: { id: item.id } })">
                  {{ $t('Edit') }}
                </b-dropdown-item>
<!--                <b-dropdown-item @click="updateItem(item, 'status', item.status === 'ON' ? 'OFF' : 'ON')">-->
<!--                  {{ $t(item.status === 'ON' ? 'Off' : 'On') }}-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item @click="updateItem(item, 'lastUpdateAt', { date: new Date() })">-->
<!--                  {{ $t('Update') }}-->
<!--                </b-dropdown-item>-->
                <b-dropdown-item @click="deleteItem(item, index)">
                  {{ $t('Delete') }}
                </b-dropdown-item>

              </b-dropdown>
            </template>

            <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import { BTable, BPopover, VBTooltip, BFormRadioGroup } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { formatDate } from "@core/utils/filter";
import * as fields from '@/@constants/fields'
import DocumentsList from '@/views/components/DocumentsList.vue'

export default {
  name: 'AiConnections',
  components: { DocumentsList, BTable, BPopover, BFormRadioGroup },
  data: () => ({
    items: [],
    aiOptions: [
      'QUICK_CHAT',
      'OPENAI_ASSISTANT',
    ],
    globalSettings: null,
    loading: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    searchQuery: '',
    connectionFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'name', label: 'Name', visible: true },
      { key: 'model', label: 'ai.reports.Version', visible: true },
      { key: 'files', label: 'PostedData', visible: true },
      // { key: 'dataStatus', label: 'DataStatusInSalesWizard', visible: true },
      { key: 'used', label: 'Wear', visible: true },
      // { key: 'wear', label: 'Used', visible: true },
      { key: 'createdAt', label: 'CreationTime', visible: true },
      { key: 'updatedAt', label: 'LastUpdate', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      // { key: 'effects', label: 'Effects', visible: true },
      // { key: 'status', label: 'task.Status', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
  }),
    directives: {
      'b-tooltip': VBTooltip,
    },
  computed: {
    fields() {
      return this.connectionFields.filter(field => field.visible)
    },
    allowForDev() {
      const is4B = origin === 'https://4bsystems.saleswizard.pl'
      const isTest = origin === 'https://test.saleswizard.pl'
      const isD1 = origin === 'https://d1.saleswizardapp.com'
      const local = origin === 'https://crm.lasland.pl'
      const campter = origin === 'http://localhost:8080'
      const network = origin.includes('192') || origin.includes('172')

      return (is4B || isTest || isD1 || local || network || campter)
    },
    isKronen() {
      return window.location.origin.includes('kronen')
    }
  },
  async mounted() {
      // this.setSampleData()
    await Promise.all([
        this.loadData(),
        this.loadGlobalSettings(),
    ])
  },
  methods: {
    formatDate,
    async loadData() {
      this.loading = true
        try {
          const params = {
            fields_load: 'AI_ENVIRONMENT',
            page: this.pagination.currentPage,
            limit: this.pagination.perPage,
          }

           if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.searchQuery.length) {
          const searchString = this.searchQuery.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = ''
        }

          const { data: response } = await axiosIns.get('1/ai/salesai/environments', { params })

          this.items = response.data.items.map(e => ({ ...e, files: e.salesAiFiles.mapKeys('file') }))
          this.pagination.totalRecords = response.data.totalItemCount

          this.$emit('get-enviroments', response.data.items)

          return response.data.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

          return err
        } finally {
          this.loading = false
        }
    },
    async loadGlobalSettings() {
      try {
        const { data: response } = await axiosIns.get('1/settings/global', { params: { fields_load: fields.SETTINGS_GLOBAL } })
        this.globalSettings = response.data.item

        return response.data.item || null
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

        return err
      }
    },

      async deleteItem({ id }, index) {
          const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
          if (!confirmed) return

          try {
              await axiosIns.delete('1/ai/salesai/environments', { data: JSON.stringify([id]) })

              this.$delete(this.items, index)

              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
              this.$emit('get-enviroments', this.items)
              this.$emit('reload-config')
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
      },
      async updateItem({ id }, field, value) {
          try {
              await axiosIns.patch('1/ai/salesai/environments', { [field]: value })

              this.$set(this.items[index], field, value)

              this.showToast('success', this.$i18n.t('success.contactUpdated'))
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
      },

      getStatusVariant(value) {
        if (['ACTIVE', 'ON'].includes(value)) return 'light-success'
        if (['INACTIVE', 'OFF'].includes(value)) return 'light-danger'

          return 'light-primary'
      },

    setSampleData() {
      this.items = [
        // Sample Data
        {
          id: 1,
          name: 'Sample connection',
          connectedData: [
            { id: 1, name: 'Produkty' },
            { id: 2, name: 'Reklamacje' },
          ],
          postedData: [
            { id: 1, name: 'Procedury reklamacji.doc' },
            { id: 2, name: 'Procedury reklamacji 2.pdf' },
          ],
          dataStatus: 'ACTIVE',
          used: [
            { id: 1, name: 'WhatsApp' },
            { id: 2, name: 'Mobile app' },
            { id: 3, name: 'Email' },
          ],
          wear: '1000',
          createdAt: { date: new Date() },
          lastUpdateAt: { date: new Date() },
          createdBy: this.$store.getters['auth/getCurrentUser'],
          effectPositive: 10,
          effectNegative: 2,
          status: 'ON',
        },
      ]
    },
  },
}
</script>

<style scoped>

</style>
