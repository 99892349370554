var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSaveSettings}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('b-card',{staticClass:"position-relative",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('Ringostat'))+" ")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('APIKey')}},[_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})],1)],1)],1),_c('div',{staticClass:"divider text-primary divider-left"},[_c('div',{staticClass:"divider-text font-medium-1"},[_vm._v(" "+_vm._s(_vm.$t('Users'))+" ")])]),_c('b-row',[_vm._l((_vm.users),function(userRecord,index){return _c('b-col',{key:("user_" + index),attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-card',{staticClass:"rounded border",attrs:{"no-body":""}},[_c('div',{staticClass:"py-75 px-1"},[_c('sw-select',{attrs:{"name":_vm.$t('User')}},[_c('v-select',{attrs:{"options":_vm.userList,"label":"firstName","filterable":false,"selectable":function (a) { return !_vm.users.some(function (e) { return e.user.id === a.id; }); }},on:{"search":_vm.searchUsers},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar }}})],1)]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar }}})],1)]}}],null,true),model:{value:(userRecord.user),callback:function ($$v) {_vm.$set(userRecord, "user", $$v)},expression:"userRecord.user"}})],1),_c('b-form-group',{attrs:{"label":"Ringostat SIP"}},[_c('b-form-input',{model:{value:(userRecord.ringostatSip),callback:function ($$v) {_vm.$set(userRecord, "ringostatSip", $$v)},expression:"userRecord.ringostatSip"}})],1),_c('b-button',{staticClass:"w-100",attrs:{"size":"sm","variant":"flat-danger"},on:{"click":function($event){return _vm.$delete(_vm.users, index)}}},[_c('sw-icon',{staticClass:"mr-25",attrs:{"icon":"TrashIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)],1)])],1)}),_c('b-col',{staticClass:"pb-1 mb-1",attrs:{"sm":"12","md":"6","lg":"4"},on:{"click":_vm.addUser}},[_c('b-alert',{staticClass:"d-flex align-items-center justify-content-center h-100 cursor-pointer",attrs:{"variant":"primary","show":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")]),_c('sw-icon',{attrs:{"icon":"PlusIcon","size":"25"}})],1)],1)],2),_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}})],1),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSaveSettings}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }