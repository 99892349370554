<template>
  <b-row>
    <b-col
      md="4"
      sm="12"
    >
      <div class="form-label-group">
        <b-form-input
          id="template-name"
          v-model="template.name"
          :placeholder="$t('Title')"
        />
        <label for="template-name">{{ $t('Title') }}</label>
      </div>
      <div
        class="form-label-group"
      >
        <!-- Preview button -->
        <b-button
          variant="primary"
          size="sm"
          class="preview_swiper mr-50 mb-25"
          @click="templatePreview = !templatePreview"
        >
          <feather-icon :icon="!templatePreview ? 'EyeIcon' : 'EyeOffIcon'" />
        </b-button>
        <!-- Shortcodes button -->
        <b-button
          v-if="!templatePreview"
          id="shortcodes_btn"
          class="mr-50 mb-25"
          variant="primary"
          size="sm"
          @click="$emit('open-modal')"
        >
          <feather-icon icon="GridIcon" />
        </b-button>
        <!-- Content -->
        <div v-if="!templatePreview">
          <b-form-textarea
            id="template-content"
            ref="sms_content"
            v-model="template.content"
            :state="template.content.length <= 500 ? (template.content.length > 0 ? true : null) : false"
            :placeholder="$t('templates.TemplateContent')"
            class="pt-1 char-textarea"
            rows="8"
          />
          <small
            class="textarea-counter-value float-right"
            :class="template.content.length >= 500 ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ template.content.length }}</span> / 500
          </small>
        </div>
        <!-- Content - Preview -->
        <div
          v-else
          style="padding: 1rem 1rem 1.2rem; border: 1px solid #d8d6de; border-radius: 0.357rem;"
          :inner-html.prop="template.content | shortcodePreview(shortcodes)"
        />
      </div>
      <b-button
        size="sm"
        class="mt-1"
        variant="success"
        :disabled="!template.content || !template.name || template.content.length >= 500 || !(template.content.length !== 0)"
        @click="saveTemplate"
      >
        {{ editedTemplateId ? $t('Update') : $t('Save') }}
      </b-button>
      <b-button
        v-if="editedTemplateId"
        size="sm"
        class="ml-50 mt-1"
        @click="editField(null)"
      >
        {{ $t('Cancel') }}
      </b-button>
    </b-col>

    <b-col
      md="8"
      sm="12"
      class="mt-1 mt-md-0"
    >
      <b-table
        ref="selectableTable"
        class="table-wrap-words"
        :items="templates"
        :fields="fields"
        striped
        responsive
        selectable
        select-mode="multi"
        :busy="loading"
        show-empty
        @row-selected="onRowSelected"
      >
        <template #empty>
          <div
            class="text-center py-1 text-primary font-weight-bold"
          >
            <feather-icon
              size="17"
              icon="XCircleIcon"
            />
            {{ $t('NoData') }}
          </div>
        </template>
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>
        <template #head(selected)>
          <b-form-checkbox
            variant="primary"
            :checked="templates.length === selectedTemplates.length"
            @change="changeAllSelection"
          />
        </template>
        <template #cell(selected)="rowSelected">
          <b-form-checkbox
            v-model="selectedTemplates"
            variant="primary"
            :value="rowSelected.item"
            @change="changeSelection(rowSelected.rowSelected, rowSelected.index)"
          />
        </template>
        <template #cell(content)="row">
          {{ row.value | truncate(20, '...') }}
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            class="mr-50"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="!row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'" />
          </b-button>
          <b-button
            size="sm"
            class="mr-50"
            @click="editField(row.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col :inner-html.prop="row.item.content | shortcodePreview(shortcodes)" />
            </b-row>
          </b-card>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <vue-select
            v-model="pagination.limit"
            style="min-width: 75px"
            class="per-page-selector d-inline-block mr-50 pb-50 select-size-sm"
            :options="[5, 10, 15, 25]"
            :clearable="false"
            @input="reloadTemplates(pagination.currentPage, $event)"
          />
        </div>
        <div>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rows"
            :per-page="pagination.limit"
            align="center"
            @change="reloadTemplates($event, pagination.limit)"
          />
        </div>
      </div>
    </b-col>

    <transition name="fade">
      <div
        v-if="selectedTemplates.length"
        style="position: fixed; right: 30px; bottom: 20px"
        class="shadow"
      >
        <b-button
          variant="danger"
          class="d-flex align-items-center"
          size="sm"
          @click="removeTemplates"
        >
          <feather-icon icon="TrashIcon" />
          <span class="pr-1 pl-25">{{ $t('Delete') }}</span>
          <b-badge variant="light text-danger">
            {{ selectedTemplates.length }}
          </b-badge>
        </b-button>
      </div>
    </transition>
  </b-row>
</template>

<script>
import VueSelect from 'vue-select'
import {
  BFormTextarea, BTable, BPagination,
} from 'bootstrap-vue'
import { GET_TEMPLATES, SAVE_TEMPLATE, DELETE_TEMPLATE } from '@/@constants/mutations'
import { mapGetters } from 'vuex'

export default {
  name: 'Sms',
  components: {
    BFormTextarea,
    BTable,
    BPagination,
    VueSelect,
  },
  data: () => ({
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      limit: 5,
      currentPage: 1,
    },
    loading: false,
    selectedTemplates: [],
    fields: [
      {
        key: 'selected',
      },
      {
        key: 'name',
        label: 'Title',
      },
      {
        key: 'content',
        label: 'Template',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
    editedTemplateId: null,
    template: {
      content: '',
      name: '',
    },
    templatePreview: false,
  }),
  computed: {
    ...mapGetters({
      shortcodes: 'templates/getShortcodes',
      templates: 'templates/getSmsTemplates',
      rows: 'templates/getSmsTemplatesLength',
    }),
  },
  mounted() {
    this.reloadTemplates()
  },
  methods: {
    reloadTemplates(page = this.pagination.currentPage, limit = this.pagination.limit) {
      this.loading = true

      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'sms', page: this.rows < limit ? 1 : page, limit })
        .then(() => {
          setTimeout(() => { this.loading = false }, 500)
        })
        .catch(() => {
          this.loading = false
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    editField(item) {
      if (item) {
        this.editedTemplateId = item.id
        this.template = {
          content: item.content.replaceAll('<br>', '\n'),
          name: item.name,
        }
      } else {
        this.editedTemplateId = null
        this.template = {
          content: '',
          name: '',
        }
      }
    },
    changeSelection(e, index) {
      if (!e) this.$refs.selectableTable.selectRow(index)
      else this.$refs.selectableTable.unselectRow(index)
    },
    changeAllSelection() {
      if (this.templates.length === this.selectedTemplates.length) this.$refs.selectableTable.clearSelected()
      else this.$refs.selectableTable.selectAllRows()
    },
    onRowSelected(items) {
      this.selectedTemplates = items
    },
    insertShortcode(shortcode) {
      const { content } = this.template
      const textarea = this.$refs.sms_content.$refs.input
      const sentenceLength = content.length
      let position = textarea.selectionStart

      if (position === undefined) {
        position = sentenceLength - 1
      }

      const before = content.substr(0, position)
      const after = content.substr(position, sentenceLength)

      this.template.content = before + shortcode.shortcode + after

      this.$nextTick().then(() => {
        textarea.focus()
      })
    },
    saveTemplate() {
      const { template } = this
      if (this.editedTemplateId) template.id = this.editedTemplateId
      this.$store.dispatch(`templates/${SAVE_TEMPLATE}`, { type: 'sms', template })
        .then(() => {
          this.editedTemplateId = null
          this.template = {
            content: '',
            name: '',
          }
          this.reloadTemplates()
          this.showToast('success', this.editedTemplateId ? this.$i18n.t('TemplateUpdated') : this.$i18n.t('TemplateAdded'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
    removeTemplates() {
      const templatesToRemove = this.selectedTemplates.map(template => template.id.toString())
      this.$store.dispatch(`templates/${DELETE_TEMPLATE}`, { templatesToRemove, type: 'sms' })
        .then(() => {
          this.reloadTemplates()
          this.showToast('success', this.$i18n.t('TemplatesRemoved'))
        })
        .catch(() => {
          this.showToast('error', this.$i18n.t('ProblemOccured'))
        })
    },
  },
}
</script>

<style scoped>

</style>
